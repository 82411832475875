import React from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { SeoMetrics } from '@component/seo/seo-metrics';
import { PageEnum } from '@enum/page.enum';
import { StepsNavigation } from '@page/main-pages/common/steps-navigation/steps-navigation';
import { PrivacyDocumentsPropsInterface } from '@page/main-pages/main-page-common/main-page-common.interface';
import {
    PrivacyContainer,
    PrivacyContent,
    PrivacyInnerContainer,
    PrivacyParagraph,
    PrivacyParagraphBottomIndent,
    PrivacyParagraphLeftIndent,
    PrivacyTitle,
} from '@page/main-pages/main-pages.styles';

const ScamsReimbursement = ({ withNavigation = true }: PrivacyDocumentsPropsInterface) => (
    <PrivacyContainer>
        <SeoMetrics
            title={LocalizationEnum.ScamsReimbursementTitle}
            description={LocalizationEnum.ScamsReimbursementDescription}
        />
        {withNavigation && (
            <StepsNavigation
                secondLevel={{ path: PageEnum.LegalDocuments, title: LocalizationEnum.CommonLegalDocuments }}
                thirdLevel={{
                    path: PageEnum.ScamsReimbursement,
                    title: LocalizationEnum.ScamsReimbursement,
                }}
            />
        )}
        <PrivacyInnerContainer>
            <PrivacyTitle>
                <Translate langKey={LocalizationEnum.ScamsReimbursement} />
            </PrivacyTitle>
            <PrivacyContent>
                <PrivacyParagraph>Effective Date: October 7, 2024</PrivacyParagraph>
                <PrivacyParagraph>
                    From 7 October 2024, the Payment Systems Regulator (PSR) is introducing new regulations to protect
                    victims of authorised push payment (APP) scams – that’s where scammers trick people into sending
                    them money by bank transfer.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    This page explains how we’ll handle APP scam claims under the new regulations, so you know what to
                    expect. There won’t be much change from the way we handled these claims before, and most people who
                    make an APP scam claim will get their money back.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    The information on this page covers personal accounts, plus payments made by microenterprises and
                    small charities.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    The following information applies to payments made through the Faster Payment System. If you’ve paid
                    a scammer by credit or debit card, cash, cheque or international transfer, you aren’t covered by the
                    new regulations or the CRM.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    If you think that you are a victim of an APP scam, you should contact us immediately on
                    support@sends.co to report it and start your claim.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    You must make your claim within 13 months of your last payment to the scammer, but we highly
                    recommend you to contact us as soon as possible so we have a better chance of getting your money
                    back.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    You may consider yourself to be a victim of an APP scam if:
                    <PrivacyParagraphLeftIndent>
                        A. A scammer tricked you into thinking you were sending money to someone other than the person
                        who received it.
                        <PrivacyParagraphBottomIndent />
                        OR
                        <PrivacyParagraphBottomIndent />
                        B. You knew who you were sending money to, but they tricked you into doing it by lying about
                        what the payment was for.
                    </PrivacyParagraphLeftIndent>
                </PrivacyParagraph>
                <PrivacyParagraph>
                    After you make a claim we’ll ask you to give as much information as possible and send any possible
                    evidence you have. Then, if you’ve been scammed, we’ll:
                    <PrivacyParagraphLeftIndent>
                        1. Share the details of your claim with any banks you sent money to, so we can work with them to
                        investigate;
                        <PrivacyParagraphBottomIndent />
                        2. Try to get your money back from the scammer’s account. If we’re successful, we’ll send you
                        any money we get;
                        <PrivacyParagraphBottomIndent />
                        3. If we are unable to recover all your funds, we will review your case based on the new PSR
                        regulations to determine if you qualify for any further action. If you are not eligible, your
                        claim will be declined.
                        <PrivacyParagraphBottomIndent />
                    </PrivacyParagraphLeftIndent>
                </PrivacyParagraph>
                <PrivacyParagraph>
                    We’ll certainly reject your claim if:
                    <PrivacyParagraphLeftIndent>
                        <PrivacyParagraphBottomIndent />
                        - You sent money to a genuine person or company who wasn’t trying to trick or scam you;
                        <PrivacyParagraphBottomIndent />
                        - You acted fraudulently or were part of the scam;
                        <PrivacyParagraphBottomIndent />
                        - More than 13 months have passed since you sent money to the scammer;
                        <PrivacyParagraphBottomIndent />
                        - The account you sent money to is with a credit union, municipal bank or national savings bank;
                        <PrivacyParagraphBottomIndent />
                        - The payment was to your own account with us or another bank;
                        <PrivacyParagraphBottomIndent />
                        - You didn’t provide information we asked for to help us investigate your claim;
                        <PrivacyParagraphBottomIndent />
                        - We asked you to report the scam to the police and you refused;
                        <PrivacyParagraphBottomIndent />- You didn’t take reasonable steps to protect your money. For
                        example, if you knowingly gave us the wrong payment reason
                    </PrivacyParagraphLeftIndent>
                </PrivacyParagraph>
                <PrivacyParagraph>
                    A payment qualifies for reimbursement if:
                    <PrivacyParagraphLeftIndent>
                        <PrivacyParagraphBottomIndent />
                        - Payment was made on or after 7 October 2024
                        <PrivacyParagraphBottomIndent />
                        - The claim does not not fall under any categories for rejection or the customer is Vulnerable;
                        <PrivacyParagraphBottomIndent />
                        - The claim is honest;
                        <PrivacyParagraphBottomIndent />
                        - The claim is not part of a civil dispute;
                        <PrivacyParagraphBottomIndent />
                        - The payment was not for unlawful purpose;
                        <PrivacyParagraphBottomIndent />- The claim was reported within 13 months of the final payment
                    </PrivacyParagraphLeftIndent>
                </PrivacyParagraph>
                <PrivacyParagraph>
                    We’ll apply a £100 excess from the total refund. This is a fixed amount for each claim you make. For
                    example, if your claim is for £1000, your refund will be £900. If you are considered a vulnerable
                    customer, the £100 excess and the consumer standard of caution exception may not apply.
                </PrivacyParagraph>
                <PrivacyParagraph>
                    We’ll contact you within 5 working days, but it may take up to 35 working days to complete the
                    investigation. We have the right to decline your claim in case we have any reasons stated above.
                </PrivacyParagraph>
                <PrivacyParagraph>The most we can cover under the new rules is £85,000.</PrivacyParagraph>
            </PrivacyContent>
        </PrivacyInnerContainer>
    </PrivacyContainer>
);

export default ScamsReimbursement;
