import React from 'react';
import { Helmet } from 'react-helmet';

import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

type SeoMetricsType = {
    title: LocalizationEnum;
    description: LocalizationEnum | string;
};

export const SeoMetrics = ({ title, description }: SeoMetricsType) => {
    const titleText = useLocalizationText(title);
    const descriptionText = useLocalizationText(description);

    return (
        <Helmet defer>
            <meta charSet="utf-8" />
            <title>{titleText}</title>
            <meta property="description" content={descriptionText} />
            <meta property="og:description" content={descriptionText} />
            <meta property="og:title" content={titleText} />
            <meta property="og:site_name" content="Sends" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://sends.co/favicon/og-img-preview.gif" />
            <meta property="og:image:secure_url" content="https://sends.co/favicon/og-img-preview.gif" />
            <meta property="og:image:type" content="image/gif" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="627" />
            {/* Twitter tags */}
            <meta name="twitter:creator" content="Sends" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content="https://sends.co/favicon/og-img-preview.gif" />
            <meta name="twitter:title" content={titleText} />
            <meta name="twitter:description" content={descriptionText} />
            {/* End Twitter tags */}
            <link rel="canonical" href={window.location.href.replace(window.location.search, '')} />
        </Helmet>
    );
};
