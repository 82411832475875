import { Form, useField } from 'formik';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Notification } from '@shared-atom/elpaso-kit/notification';
import { NotificationSizeEnum, NotificationTypeEnum } from '@shared-atom/elpaso-kit/notification/types';
import { ModelContext } from '@shared-component/modal/modal.context';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { Account } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { PaymentBreadcrumbs } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs';
import {
    PAYMENT_BREADCRUMBS,
    PaymentStepsEnum,
} from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';
import { ValidationSchemaTypeInterface } from '@component/modal/modals/payment/payment-form/payment-form.interface';
import { PaymentUpdateStepsEnum } from '@component/modal/modals/payment/payment-update-steps/payment-update-hook.enum';
import {
    ButtonsWrapper,
    PaymentErrorWrapper,
    PaymentFormStepsWrapper,
} from '@component/modal/modals/payment/payment.modal-style';
import { useActiveAccounts } from '@hook/active-accounts/active-accounts.hook';

import { PaymentUpdateSteps } from '../../payment-update-steps/payment-update-steps';
import { AmountCurrencyForm } from './amount-currency-form/amount-currency-form';
import { AccountCurrencyFormEnum } from './amount-currency-form/amount-currency-form.enum';
import { PaymentDetailsForm } from './payment-details-form/payment-details-form';
import { usePaymentFormSteps } from './payment-form-steps.hook';

interface PaymentFormStepsProps {
    activeStep: number;
    isLoading: boolean;
    isSignMode: boolean;
    accounts: Account[];
    signPaymentError?: string;
    setSignPaymentError: Dispatch<SetStateAction<string>>;
    paymentUpdateStep: PaymentUpdateStepsEnum;
    onPrevStep: OnEventEmptyType;
    onStep: OnEventType<number>;
    onOtpSubmit: OnEventType<string>;
    onPaymentSign: OnEventEmptyType;
    currentValidationSchema: ValidationSchemaTypeInterface;
    onUpdateValidationSchema: OnEventType<ValidationSchemaTypeInterface>;
    setModalTitle: Dispatch<SetStateAction<LocalizationEnum>>;
    isPaymentError: boolean;
    clearPaymentFlow: Dispatch<SetStateAction<null>>;
    restrictedCountries: string[];
    onSendOtpCode: OnEventEmptyType;
    availableListCountries: BaseOptionInterface[];
}

export const PaymentFormSteps = ({
    onStep,
    accounts,
    isSignMode,
    activeStep,
    onPaymentSign,
    signPaymentError,
    setSignPaymentError,
    paymentUpdateStep,
    onPrevStep,
    isLoading,
    currentValidationSchema,
    onUpdateValidationSchema,
    onOtpSubmit,
    setModalTitle,
    isPaymentError,
    clearPaymentFlow,
    restrictedCountries,
    onSendOtpCode,
    availableListCountries,
}: PaymentFormStepsProps) => {
    const [{ value: paymentAccountId }] = useField(AccountCurrencyFormEnum.PaymentCurrency);
    const { setOnBack } = useContext(ModelContext);
    const canCreate = useActiveAccounts();

    const selectedAccount = useMemo(
        () => accounts.find(account => account.accountId === paymentAccountId),
        [paymentAccountId, accounts]
    );

    const { fee, purposeCodes, isNextStepDisable, isAdditionalDataLoading } = usePaymentFormSteps(
        currentValidationSchema,
        onUpdateValidationSchema,
        selectedAccount as Account,
        restrictedCountries
    );

    const insufficientFundsErrorText = useLocalizationText(LocalizationEnum.CommonInsufficientFunds);
    const [, , { setError }] = useField(AccountCurrencyFormEnum.Amount);
    const isInsufficientFunds = useMemo(
        () => selectedAccount !== undefined && isExist(fee.amount) && selectedAccount.balance < fee.total,
        [selectedAccount, fee.amount, fee.total]
    );

    useEffect(() => {
        setError(isInsufficientFunds ? insufficientFundsErrorText : undefined);
    }, [isInsufficientFunds]);

    const isFirstStepActive = activeStep === 0;
    const isLastStepActive = activeStep === PAYMENT_BREADCRUMBS.length;
    const activeStepValue = isLastStepActive
        ? PaymentStepsEnum.PaymentConfirmation
        : PAYMENT_BREADCRUMBS[activeStep].value;

    useEffect(() => {
        if (activeStepValue !== PaymentStepsEnum.AmountCurrency) {
            setOnBack(() => onPrevStep);
        } else {
            setOnBack(() => clearPaymentFlow);
        }
    }, [activeStepValue]);

    return (
        <Form>
            <PaymentFormStepsWrapper>
                {activeStepValue !== PaymentStepsEnum.PaymentConfirmation && (
                    <PaymentBreadcrumbs breadcrumbs={PAYMENT_BREADCRUMBS} activeStep={activeStep} onStep={onStep} />
                )}
                {!isSignMode && activeStepValue === PaymentStepsEnum.AmountCurrency && (
                    <AmountCurrencyForm
                        accounts={accounts}
                        fee={fee}
                        purposeCodes={purposeCodes}
                        currency={selectedAccount?.currency.code as string}
                        availableListCountries={availableListCountries}
                    />
                )}
                {activeStepValue === PaymentStepsEnum.PaymentDetails && <PaymentDetailsForm />}
                {activeStepValue === PaymentStepsEnum.PaymentConfirmation && (
                    <PaymentUpdateSteps
                        currency={selectedAccount?.currency.code as string}
                        fee={fee}
                        selectedAccount={selectedAccount}
                        signPaymentError={signPaymentError}
                        setSignPaymentError={setSignPaymentError}
                        step={paymentUpdateStep}
                        onPrevStep={onPrevStep}
                        onOtpSubmit={onOtpSubmit}
                        onPaymentSign={onPaymentSign}
                        isSignMode={isSignMode}
                        isLoading={isLoading || isAdditionalDataLoading}
                        canSign={canCreate}
                        setModalTitle={setModalTitle}
                        ibanPaymentFlow
                        isPaymentError={isPaymentError}
                        onSendOtpCode={onSendOtpCode}
                    />
                )}

                {!canCreate && !isLastStepActive && (
                    <PaymentErrorWrapper>
                        <Notification
                            text={LocalizationEnum.PaymentFormError}
                            size={NotificationSizeEnum.L}
                            type={NotificationTypeEnum.ACCENT}
                        />
                    </PaymentErrorWrapper>
                )}
            </PaymentFormStepsWrapper>
            <ButtonsWrapper isDisable={isLastStepActive}>
                {!isFirstStepActive && !isLastStepActive && (
                    <>
                        <Button
                            onClick={onPrevStep}
                            type={ButtonTypeEnum.GHOST}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonBack}
                            iconBefore={ArrowLeft}
                        />
                        <Button
                            type={ButtonTypeEnum.COLOR}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonNext}
                            isSubmit
                            isLoading={isLoading || isAdditionalDataLoading}
                        />
                    </>
                )}
                {isFirstStepActive && (
                    <>
                        <Button
                            onClick={clearPaymentFlow as unknown as ClickEventType}
                            type={ButtonTypeEnum.GHOST}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonBack}
                            iconBefore={ArrowLeft}
                        />
                        <Button
                            type={ButtonTypeEnum.COLOR}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonNext}
                            isSubmit
                            isDisabled={!canCreate || isNextStepDisable || isInsufficientFunds}
                            isLoading={isLoading || isAdditionalDataLoading}
                        />
                    </>
                )}
            </ButtonsWrapper>
        </Form>
    );
};
