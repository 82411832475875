export enum LocalizationEnum {
    CommonLogin = 'common.login',
    CommonEmail = 'common.email',
    RegistrationFormTitle = 'registration.form.title',
    CommonPassword = 'common.password',
    CommonPasswordRepeat = 'common.password.repeat',
    RegistrationPhoneTitle = 'registration.phone.title',
    RegistrationOtpTitle = 'registration.otp.title',
    RegistrationOtpDescription = 'registration.otp.description',
    UserTypeLegacy = 'user.type.legacy',
    UserTypePrivate = 'user.type.private',
    UserTypeLegacyDescription = 'user.type.legacy.description',
    UserTypePrivateDescription = 'user.type.private.description',
    CommonSelect = 'common.select',
    PrivateFormButtonContinueTitle = 'private.form.button.continue.title',
    CommonBack = 'common.back',
    UploadDocumentSelectTitle = 'upload.document.select.title',
    UploadDocumentSelectInfo = 'upload.document.select.info',
    UploadDocumentSelectUploading = 'upload.document.select.uploading',
    UploadDocumentSelectUploaded = 'upload.document.select.uploaded',
    LoginFormTitle = 'login.form.title',
    LoginFormWelcome = 'login.form.welcome',
    LoginFormDescription = 'login.form.description',
    LoginFormInputTitle = 'login.form.input.title',
    PrivateDocumentCommentShow = 'private.document.comment.show',
    PrivateDocumentCommentTitle = 'private.document.comment.title',
    DashboardAccountsButtonTitle = 'dashboard.accounts.button.title',
    DashboardTitle = 'dashboard.title',
    DashboardPaymentNewButtonTitle = 'dashboard.payment.new.button.title',
    RegistrationLegalCompanyButtonTitle = 'registration.legal.company.button.title',
    RegistrationPasswordTooltipValidationDigit = 'registration.password.tooltip.validation.digit',
    RegistrationPasswordTooltipValidationSpecialCharacter = 'registration.password.tooltip.validation.special.character',
    RegistrationPasswordTooltipValidationLowerCase = 'registration.password.tooltip.validation.lower.case',
    RegistrationPasswordTooltipValidationUpperCase = 'registration.password.tooltip.validation.upper.case',
    RegistrationPasswordTooltipValidationMinLength = 'registration.password.tooltip.validation.min.length',
    RegistrationPasswordTooltipValidationMaxLength = 'registration.password.tooltip.validation.max.length',
    RegistrationPasswordTooltipValidationLatinLetter = 'registration.password.tooltip.validation.latin.letter',
    PaymentFormTitle = 'payment.form.title',
    PaymentFormAccountTitle = 'payment.form.account.title',
    CommonAmount = 'common.amount',
    RegistrationUserTypeTitle = 'registration.user.type.title',
    StatementFormDateRange = 'statement.form.date.range',
    CreatePartnerFormBic = 'create.partner.form.bic',
    CreatePartnerFormAccount = 'create.partner.form.account',
    CreatePartnerFormFirstName = 'create.partner.form.first.name',
    CreatePartnerFormBeneficiaryCountry = 'create.partner.form.beneficiary.country',
    CreatePartnerFormBeneficiaryCity = 'create.partner.form.beneficiary.city',
    CreatePartnerFormBeneficiaryZip = 'create.partner.form.beneficiary.zip',
    CreatePartnerFormBeneficiaryAddress = 'create.partner.form.beneficiary.address',
    CreatePartnerFormBeneficiaryInfoTitle = 'create.partner.form.beneficiary.info.title',
    CreatePartnerFormBeneficiaryInfoDescription = 'create.partner.form.beneficiary.info.description',
    CommonPhone = 'common.phone',
    ChangePasswordFormNewPassword = 'change.password.form.new.password',
    CommonCancel = 'common.cancel',
    UserShortProfileLinkContactUs = 'user.short.profile.link.contact.us',
    ChangePasswordSubmitButtonTitle = 'change.password.submit.button.title',
    UserShortProfileButtonChangePasswordTitle = 'user.short.profile.button.change.password.title',
    CommonConfirm = 'common.confirm',
    RequestStatementModalSubmitButtonTitle = 'request.statement.modal.submit.button.title',
    AddressBookTitle = 'address.book.title',
    AddressBookTableTitle = 'address.book.table.title',
    AddressBookPageButtonTitle = 'address.book.page.button.title',
    AddressBookModalCreateTitle = 'address.book.modal.create.title',
    AddressBookTableFilterTitle = 'address.book.table.filter.title',
    FeaturesPaymentsTitle = 'features.payments.title',
    CommonTableEmptyTitle = 'common.table.empty.title',
    HeaderLinkPricing = 'header.link.pricing',
    BusinessCardBusiness = 'business.card.business',
    CommonOpenAccount = 'common.open.account',
    CommonAcceptAll = 'common.accept.all',
    CommonFirstName = 'common.first.name',
    CommonLastName = 'common.last.name',
    HelpLocationDescription2 = 'help.location.description_2',
    ForgotPassword = 'forgot.password',
    ForgotPasswordSendLink = 'forgot.password.send.link',
    ForgotPasswordFormTitle = 'forgot.password.form.title',
    ForgotPasswordSentSuccessMessageTitle = 'forgot.password.sent.success.message.title',
    UpdatePasswordSuccessMessage = 'update.password.success.message',
    LoginFormError = 'login.form.error',
    ExchangeModalInformationSelling = 'exchange.modal.information.selling',
    ExchangeModalInformationBuying = 'exchange.modal.information.buying',
    ExchangeModalInformationExchangeRate = 'exchange.modal.information.exchange.rate',
    ExchangeModalInformationExchangeFee = 'exchange.modal.information.exchange.fee',
    ExchangeModalFormTitle = 'exchange.modal.form.title',
    ExchangeFormSellTitle = 'exchange.form.sell.title',
    ExchangeFormBuyTitle = 'exchange.form.buy.title',
    ExchangeFormAmountTitle = 'exchange.form.amount.title',
    ExchangeFormQuoteTitle = 'exchange.form.quote.title',
    ChangePasswordFormError = 'change.password.form.error',
    AddCurrencyModalTitle = 'add.currency.modal.title',
    AddCurrencyModalDescription = 'add.currency.modal.description',
    AccountDetailsModalTitle = 'account.details.modal.title',
    AccountDetailsCardIban = 'account.details.card.iban',
    AccountDetailsCardBankName = 'account.details.card.bank.name',
    AccountDetailsCardBankAddress = 'account.details.card.bank.address',
    AccountDetailsCardBankCountry = 'account.details.card.bank.country',
    HelpLinksItemTitle4 = 'help.links.item.title_4',
    ExchangeModalInformationConversionDate = 'exchange.modal.information.conversion.date',
    CommonClose = 'common.close',
    PricesDescription = 'prices.description',
    ComplaintsTitle = 'complaints.title',
    PrivacyPolicyItemsTitle = 'privacy.policy.items.title',
    PrivacyPolicyItem6 = 'privacy.policy.item_6',
    TermsAndConditionItemsTitle = 'terms.and.condition.items.title',
    HomeAboutQuestionButtonTitle = 'home.about.question.button.title',
    FooterNavProductTitle = 'footer.nav.product.title',
    FooterNavProductBankingTitle = 'footer.nav.product.banking.title',
    FooterNavProductPriceTitle = 'footer.nav.product.price.title',
    FooterNavProductAcquiringTitle = 'footer.nav.product.acquiring.title',
    FooterNavLegalTitle = 'footer.nav.legal.title',
    FooterNavProductPayoutsTitle = 'footer.nav.product.payouts.title',
    PaymentFormCurrencyAmountStepCurrency = 'payment.form.currency.amount.step.currency',
    PaymentFormCurrencyAmountStepIban = 'payment.form.currency.amount.step.iban',
    PaymentFormCurrencyAmountStepBankName = 'payment.form.currency.amount.step.bank.name',
    PaymentFormCurrencyAmountStepPayIn = 'payment.form.currency.amount.step.pay.in',
    PaymentFormCurrencyAmountStepTitle = 'payment.form.currency.amount.step.title',
    PaymentFormDetailsStepTitle = 'payment.form.details.step.title',
    PaymentFormBeneficiaryStepLastName = 'payment.form.beneficiary.step.last.name',
    PaymentFormBeneficiaryStepBeneficiaryTypeBlockTitle = 'payment.form.beneficiary.step.beneficiary.type.block.title',
    PaymentFormBeneficiaryStepNameBlockTitle = 'payment.form.beneficiary.step.name.block.title',
    PaymentFormDetailsStepDescription = 'payment.form.details.step.description',
    PaymentFormDetailsStepAttachFileTitle = 'payment.form.details.step.attach.file.title',
    PaymentFormDetailsStepAttachFileDescription = 'payment.form.details.step.attach.file.description',
    CommonNext = 'common.next',
    PaymentFormConfirmationStepTitle = 'payment.form.confirmation.step.title',
    PaymentFormConfirmationStepAmount = 'payment.form.confirmation.step.amount',
    CommissionProfileAdministrationFree = 'commission.profile.administration.free',
    PaymentFormBeneficiaryStepCompanyName = 'payment.form.beneficiary.step.company.name',
    PaymentFormCurrencyAmountStepAccountNumber = 'payment.form.currency.amount.step.account.number',
    PaymentFormCurrencyAmountStepSortCode = 'payment.form.currency.amount.step.sort.code',
    CommonCounterAccount = 'common.counter.account',
    CommonPaymentAmount = 'common.payment.amount',
    CommonPaymentFee = 'common.payment.fee',
    CommonBalanceAfterTransaction = 'common.balance.after.transaction',
    TransactionCurrencyExchangeRate = 'transaction.currency.exchange.rate',
    DashboardAddressBookEmptyTitle = 'dashboard.address.book.empty.title',
    DashboardAddressBookEmptyText = 'dashboard.address.book.empty.text',
    AddressBookModalEditTitle = 'address.book.modal.edit.title',
    ExchangeModalInformationTotalAmount = 'exchange.modal.information.total.amount',
    PaymentCreatedTitle = 'payment.created.title',
    PaymentSignedTitle = 'payment.signed.title',
    SmsVerificationTitle = 'sms.verification.title',
    PaymentSignedDescription1 = 'payment.signed.description1',
    PaymentSignedDescription2 = 'payment.signed.description2',
    CommonInsufficientFunds = 'common.insufficient.funds',
    CreatePartnerFormLastName = 'create.partner.form.last.name',
    CreatePartnerFormCompanyName = 'create.partner.form.company.name',
    CreatePartnerFormAlreadyExist = 'create.partner.form.already.exist',
    PaymentFormError = 'payment.form.error',
    PaymentSummaryRowOrderIdTitle = 'payment.summary.row.order.id.title',
    PaymentSummaryRowSettlementDateTitle = 'payment.summary.row.settlement.date.title',
    PermittedCountriesTitle = 'permitted.countries.title',
    PermittedCountriesDescription1 = 'permitted.countries.description_1',
    PermittedCountriesDescription2 = 'permitted.countries.description_2',
    PermittedCountriesDescription3 = 'permitted.countries.description_3',
    DayPickerRangeFrom = 'day.picker.range.from',
    DayPickerRangeTo = 'day.picker.range.to',
    PaymentSummaryRowTransferTitleReceipt = 'payment.summary.row.transfer.title.receipt',
    CookieInfoTitle = 'cookie.info.title',
    CookieInfoDescription = 'cookie.info.description',
    HomeServiceItemTitle4 = 'home.service.item.title_4',
    HomeServiceItemDescription4 = 'home.service.item.description_4',
    CommonDemo = 'common.demo',
    FirstScreenDiscription = 'first.screen.discription',
    OpenAccTitle = 'open.acc.title',
    OpenAccDiscription = 'open.acc.discription',
    OpenAccMembersInfo = 'open.acc.members.info',
    ManageAccCourse = 'manage.acc.course',
    FaqSectionTitle = 'faq.section.title',
    FaqSectionTab1 = 'faq.section.tab1',
    HomeServiceItemTitle1 = 'home.service.item.title_1',
    HomeServiceItemTitle2 = 'home.service.item.title_2',
    HomeServiceItemTitle3 = 'home.service.item.title_3',
    HomeServiceItemDescription1 = 'home.service.item.description_1',
    HomeServiceItemDescription2 = 'home.service.item.description_2',
    HomeServiceItemDescription3 = 'home.service.item.description_3',
    OfficeLocationWebsite = 'office.location.website',
    CopyrightTextBeforeYear = 'copyright.text.before.year',
    CopyrightTextAfterYear = 'copyright.text.after.year',
    RegistrationFormTitle1 = 'registration.form.title1',
    RegistrationFormDescription1 = 'registration.form.description1',
    ReadPrivacyPolicy = 'read.privacy.policy',
    HeaderRightTextRegistration = 'header.right.text.registration',
    HeaderRightTextSignin = 'header.right.text.signin',
    CreatePasswordTitle = 'create.password.title',
    RecoveryPassword = 'recovery.password',
    RegistrationFormTitleSignin = 'registration.form.title.signin',
    RegistrationBtn = 'registration.btn',
    OtpPhoneFormDescriptionWait = 'otp.phone.form.description.wait',
    OtpPhoneFormResendSms = 'otp.phone.form.resend.sms',
    RegistrationCompanyStepOneTitle = 'registration.company.step.one.title',
    RegistrationCompanyStepFourLabelStreet = 'registration.company.step.four.label.street',
    RegistrationCompanyStepFourLabelPostcode = 'registration.company.step.four.label.postcode',
    RegistrationCompanyStepSevenEmptyUpload = 'registration.company.step.seven.empty.upload',
    RegistrationCompanyStepSevenWait = 'registration.company.step.seven.wait',
    DragAndDropFile = 'drag.and.drop.file',
    RegistrationPersonStepEightRadiobtn2 = 'registration.person.step.eight.radiobtn2',
    RegistrationBusinessStepOneCategory1 = 'registration.business.step.one.category1',
    RegistrationBusinessStepOneCategory3 = 'registration.business.step.one.category3',
    RegistrationBusinessStepOneCategory5 = 'registration.business.step.one.category5',
    RegistrationBusinessStepOneCategory7 = 'registration.business.step.one.category7',
    RegistrationBusinessStepFourField1 = 'registration.business.step.four.field1',
    RegistrationBusinessStepFourField2 = 'registration.business.step.four.field2',
    RegistrationBusinessStepFourField3 = 'registration.business.step.four.field3',
    RegistrationBusinessStepFourField4 = 'registration.business.step.four.field4',
    RegistrationBusinessStepFiveFalse = 'registration.business.step.five.false',
    RegistrationBusinessSuccessBtn = 'registration.business.success.btn',
    RegistrationCheckMailTitleIndividual = 'registration.check.mail.title_individual',
    RegistrationPrivateAddressTitle = 'registration.private.address.title',
    RegistrationPrivateDatePlaceholder = 'registration.private.date.placeholder',
    DashboardTransactionsButtonTitle = 'dashboard.transactions.button.title',
    PaymentSummaryRowRepeatTransferTitle = 'payment.summary.row.repeat.transfer.title',
    CommonEdit = 'common.edit',
    CommonRemove = 'common.remove',
    TransactionList = 'transaction.list',
    CommonTransaction = 'common.transaction',
    CommonDate = 'common.date',
    CommonStatus = 'common.status',
    CommonPayerTitle = 'common.payer.title',
    CommonRecipientTitle = 'common.recipient.title',
    CommonReceive = 'common.receive',
    CommonSelectAccount = 'common.select.account',
    LessThanTwoThousands = 'less.than.two.thousands',
    RegistrationPrivateMonthlyIncomeTitle = 'registration.private.monthly.income.title',
    RegistrationPrivateSourceIncomeTitle = 'registration.private.source.income.title',
    CommonSalary = 'common.salary',
    CommonSaving = 'common.saving',
    CommonInterestOrDividends = 'common.interest.or.dividends',
    CommonSaleOfProperty = 'common.sale.of.property',
    CommonSaleOfShares = 'common.sale.of.shares',
    IncomeFromSelfEmployedActivities = 'income.from.self.employed.activities',
    InheritanceOrGift = 'inheritance.or.gift',
    RentalIncome = 'rental.income',
    RegistrationPrivatePurposeUsingAccountTitle = 'registration.private.purpose.using.account.title',
    SavingsAndExpensesForOwnNeeds = 'savings.and.expenses.for.own.needs',
    CommonInvestment = 'common.investment',
    BuyingAndSellingRealEstate = 'buying.and.selling.real.estate',
    SalePurchaseOfACompany = 'sale.purchase.of.a.company',
    VerificationOngoing = 'verification.ongoing',
    VerificationOngoingTitle = 'verification.ongoing.title',
    CommonYourDetails = 'common.your.details',
    CommonDocuments = 'common.documents',
    RequiredFieldErrorMessage = 'required.field.error.message',
    CommonActions = 'common.actions',
    CommonYourInfo = 'common.your.info',
    CompanyInformation = 'company.information',
    CompanyOwnership = 'company.ownership',
    BasicInformation = 'basic.information',
    LegalCompanyAddress = 'legal.company.address',
    YourBiggestCustomer = 'your.biggest.customer',
    OtherInformation = 'other.information',
    BanksAccounts = 'banks.accounts',
    RegistrationDate = 'registration.date',
    CountryOfCompanyRegistration = 'country.of.company.registration',
    RegistrationNumber = 'registration.number',
    CompanyWebsite = 'company.website',
    SaveChanges = 'save.changes',
    TownCity = 'town.city',
    BuildingNameOrNumber = 'building.name.or.number',
    TeamSize = 'team.size',
    CustomersNumber = 'customers.number',
    AnnualTurnover = 'annual.turnover',
    HowMuchToPlanSpendInElpaso = 'how.much.to.plan.spend.in.elpaso',
    OnlyMe = 'only.me',
    CategoriesOfBusiness = 'categories.of.business',
    DocumentNotUploaded = 'document.not.uploaded',
    CommonVerify = 'common.verify',
    CommonDateOfBirth = 'common.date.of.birth',
    CommonCurrentPassword = 'common.current.password',
    CommonConfirmPassword = 'common.confirm.password',
    GetFreeAccount = 'get.free.account',
    CommonForBusiness = 'common.for.business',
    CommonTariffs = 'common.tariffs',
    OverAmountPeopleWorkWithElpaso = 'over.amount.people.work.with.elpaso',
    BestSolutionFor = 'best.solution.for',
    CommonPrivateIndividuals = 'common.private.individuals',
    CommonEntrepreneurs = 'common.entrepreneurs',
    ECommerceMerchants = 'e.commerce.merchants',
    MainPageDescription = 'main.page.description',
    CommonStartups = 'common.startups',
    UkrainePrivateEntrepreneursSubtitle = 'ukraine.private.entrepreneurs.subtitle',
    TheMostCommonClientCase = 'the.most.common.client.case',
    ItCompanyFromUkraine = 'it.company.from.ukraine',
    ProfitableAndRegisteredInEurope = 'profitable.and.registered.in.europe',
    MulticurrencyAccountElpasoForBusiness = 'multicurrency.account.elpaso.for.business',
    PaymentOfRemunerationToUkraineOrOperatingExpenses = 'payment.of.remuneration.to.ukraine.or.operating.expenses',
    WhyIsItBeneficialForYourBusiness = 'why.is.it.beneficial.for.your.business',
    FeesBeneficialDescription = 'fees.beneficial.description',
    CommonLimits = 'common.limits',
    LimitsDescription = 'limits.description',
    CommonSpeed = 'common.speed',
    SpeedDescription = 'speed.description',
    ExchangeRateDescription = 'exchange.rate.description',
    UkrainePrivateEntrepreneursBottomTitle = 'ukraine.private.entrepreneurs.bottom.title',
    OverAmountEntrepreneursWorkWithElpaso = 'over.amount.entrepreneurs.work.with.elpaso',
    SwiftTransferInstructionTitle = 'swift.transfer.instruction.title',
    SwiftTransferInstructionStep1Title = 'swift.transfer.instruction.step1.title',
    SwiftTransferInstructionStep1Description = 'swift.transfer.instruction.step1.description',
    SwiftTransferInstructionStep2Title = 'swift.transfer.instruction.step2.title',
    SwiftTransferInstructionStep2Description = 'swift.transfer.instruction.step2.description',
    SwiftTransferInstructionStep3Title = 'swift.transfer.instruction.step3.title',
    SwiftTransferInstructionStep3Description = 'swift.transfer.instruction.step3.description',
    SwiftTransferInstructionStep4Title = 'swift.transfer.instruction.step4.title',
    SwiftTransferInstructionStep4Description = 'swift.transfer.instruction.step4.description',
    SwiftTransferInstructionStep5Title = 'swift.transfer.instruction.step5.title',
    SwiftTransferInstructionStep5Description = 'swift.transfer.instruction.step5.description',
    SwiftTransferInstructionConclusion = 'swift.transfer.instruction.conclusion',
    PaymentOptionsTitle = 'payment.options.title',
    PaymentOption1 = 'payment.option1',
    PaymentOption2 = 'payment.option2',
    PaymentOptions3 = 'payment.options3',
    OurBenefitsTitle = 'our.benefits.title',
    OurBenefit1Title = 'our.benefit1.title',
    OurBenefit1Description = 'our.benefit1.description',
    OurBenefit2Title = 'our.benefit2.title',
    OurBenefit2Description = 'our.benefit2.description',
    OurBenefit3Title = 'our.benefit3.title',
    OurBenefit3Description = 'our.benefit3.description',
    OurBenefit4Title = 'our.benefit4.title',
    OurBenefit4Description = 'our.benefit4.description',
    AcquiringMainTitle = 'acquiring.main.title',
    AcquiringMainDescription = 'acquiring.main.description',
    ConnectAcquiringToday = 'connect.acquiring.today',
    MoreThanAmountEntrepreneursUseAcquiringFromElpaso = 'more.than.amount.entrepreneurs.use.acquiring.from.elpaso',
    CommonSwiftTransfer = 'common.swift.transfer',
    CommissionFromOnePayment = 'commission.from.one.payment',
    DataCopiedToTheClipboard = 'data.copied.to.the.clipboard',
    NotificationTitle = 'notification.title',
    SettingsTitle = 'settings.title',
    SuccessCurrencyExchangeMessage = 'success.currency.exchange.message',
    FailureCurrencyExchangeMessage = 'failure.currency.exchange.message',
    MinAmountErrorText = 'min.amount.error.text',
    CommonEnglish = 'common.english',
    PersonalIban = 'personal.iban',
    PaymentsAcceptance = 'payments.acceptance',
    PayoutsToCards = 'payouts.to.cards',
    PaymentFormDetailsStepPlaceholder = 'payment.form.details.step.placeholder',
    MaxTextareaLengthText = 'max.textarea.length.text',
    PaymentFormBeneficiaryStepCompanyNamePlaceholder = 'payment.form.beneficiary.step.company.name.placeholder',
    PaymentFormBeneficiaryStepNamePlaceholder = 'payment.form.beneficiary.step.name.placeholder',
    PaymentFormBeneficiaryStepLastNamePlaceholder = 'payment.form.beneficiary.step.last.name.placeholder',
    OtpCodeIncorrectError = 'otp.code.incorrect.error',
    PaymentSignedDescription3 = 'payment.signed.description3',
    CommonJanuary = 'common.january',
    CommonFebruary = 'common.february',
    CommonMarch = 'common.march',
    CommonApril = 'common.april',
    CommonMay = 'common.may',
    CommonJune = 'common.june',
    CommonJuly = 'common.july',
    CommonAugust = 'common.august',
    CommonSeptember = 'common.september',
    CommonOctober = 'common.october',
    CommonNovember = 'common.november',
    CommonDecember = 'common.december',
    CommonPaymentPurpose = 'common.payment.purpose',
    CommonPaymentForCoffee = 'common.payment.for.coffee',
    CommonCardNumber = 'common.card.number',
    PleaseProvideRegionForUkraineRecipient = 'please.provide.region.for.ukraine.recipient',
    CrimeaRegion = 'crimea.region',
    VinnytskaOblast = 'vinnytska.oblast',
    VolynskaOblast = 'volynska.oblast',
    DnipropetrovskaOblast = 'dnipropetrovska.oblast',
    DonetskaOblast = 'donetska.oblast',
    ZhytomyrskaOblast = 'zhytomyrska.oblast',
    ZakarpatskaOblast = 'zakarpatska.oblast',
    ZaporizkaOblast = 'zaporizka.oblast',
    IvanoFrankivskaOblast = 'ivano.frankivska.oblast',
    KyivskaOblast = 'kyivska.oblast',
    KirovohradskaOblast = 'kirovohradska.oblast',
    LuhanskaOblast = 'luhanska.oblast',
    LvivskaOblast = 'lvivska.oblast',
    MykolaivskaOblast = 'mykolaivska.oblast',
    OdeskaOblast = 'odeska.oblast',
    PoltavskaOblast = 'poltavska.oblast',
    RivnenskaOblast = 'rivnenska.oblast',
    SumskaOblast = 'sumska.oblast',
    TernopilskaOblast = 'ternopilska.oblast',
    KharkivskaOblast = 'kharkivska.oblast',
    KhersonskaOblast = 'khersonska.oblast',
    KhmelnytskaOblast = 'khmelnytska.oblast',
    CherkaskaOblast = 'cherkaska.oblast',
    ChernivetskaOblast = 'chernivetska.oblast',
    ChernihivskaOblast = 'chernihivska.oblast',
    CommonSelectRegion = 'common.select.region',
    AccountYouPayFrom = 'account.you.pay.from',
    OnlyMastercardCardsAreAvailable = 'only.mastercard.cards.are.available',
    ItIsNecessaryToEnterTheRegionForUkraine = 'it.is.necessary.to.enter.the.region.for.ukraine',
    ThisRegionOfUkraineIsRestrictedForWithdrawals = 'this.region.of.ukraine.is.restricted.for.withdrawals',
    ThisCountryIsRestrictedForWithdrawals = 'this.country.is.restricted.for.withdrawals',
    MustBeGreaterThanX = 'must.be.greater.than.x',
    MaxLengthXNumbers = 'max.length.x.numbers',
    CommonDetails = 'common.details',
    CommonSendTo = 'common.send.to',
    ExpectationDepositTime = 'expectation.deposit.time',
    BalanceAfterTransaction = 'balance.after.transaction',
    CommonFee = 'common.fee',
    CommonInstant = 'common.instant',
    CommonToCardSmall = 'common.to.card.small',
    CommonRecipientCard = 'common.recipient.card',
    PaymentToCardIsBeingProcessed = 'payment.to.card.is.being.processed',
    PaymentToCardCreatedInTaskList = 'payment.to.card.created.in.task.list',
    PaymentErrorText = 'payment.error.text',
    CommonSupportTeam = 'common.support.team',
    CommonRepeatTransaction = 'common.repeat.transaction',
    CommonSystemError = 'common.system.error',
    CommonNationality = 'common.nationality',
    CommonSelectNationality = 'common.select.nationality',
    CommonRecipient = 'common.recipient',
    PaymentCanNotBeCreated = 'payment.can.not.be.created',
    CommonPurposeCode = 'common.purpose.code',
    CommonSelectPurposeCode = 'common.select.purpose.code',
    AccountOpeningFee = 'account.opening.fee',
    ComplexStructure = 'complex.structure',
    CommonAccountStatement = 'common.account.statement',
    ElpasoInternalReceipt = 'elpaso.internal.receipt',
    CommonEnterAmount = 'common.enter.amount',
    AcquiringRequestModalTitle = 'acquiring.request.modal.title',
    HowCanWeContactYou = 'how.can.we.contact.you',
    CommonYourName = 'common.your.name',
    CommonEmailUkFull = 'common.email.uk.full',
    WhatIsYourCompanysAnnualTurnover = 'what.is.your.companys.annual.turnover',
    LeaveYourQuestion = 'leave.your.question',
    CommonYourQuestion = 'common.your.question',
    CommonLeaveARequest = 'common.leave.a.request',
    YourRequestHasBeenSuccessfullySent = 'your.request.has.been.successfully.sent',
    CommonCardPayouts = 'common.card.payouts',
    OpenAccountPageBadge = 'open.account.page.badge',
    OpenAccountPageTitle = 'open.account.page.title',
    OpenAccountPageSubtitle = 'open.account.page.subtitle',
    PaymentTypesSupported = 'payment.types.supported',
    OpenAccountPagePaymentTypeTitle = 'open.account.page.payment.type.title',
    DedicatedEuIbanForSepa = 'dedicated.eu.iban.for.sepa',
    DedicatedEuIbanForSepaDescription = 'dedicated.eu.iban.for.sepa.description',
    WhatAreTheRequirementsForOpeningAnAccount = 'what.are.the.requirements.for.opening.an.account',
    ForALegalEntity = 'for.a.legal.entity',
    ForALegalEntityRequirements = 'for.a.legal.entity.requirements',
    ForAnIndividual = 'for.an.individual',
    ForAnIndividualRequirements = 'for.an.individual.requirements',
    StepsToGetStarted = 'steps.to.get.started',
    CommonCreateAnAccount = 'common.create.an.account',
    CommonVerifyYourAccount = 'common.verify.your.account',
    XHoursForReview = 'x.hours.for.review',
    GetYourVirtualAccountNowFor24Currencies = 'get.your.virtual.account.now.for.24.currencies',
    CommonStep = 'common.step',
    PaymentCannotBeCreatedBecauseOfTheBeneficiarysCountry = 'payment.cannot.be.created.because.of.the.beneficiarys.country',
    InvalidCardNumber = 'invalid.card.number',
    CommonPreferences = 'common.preferences',
    CookieSettingsModalTitle = 'cookie.settings.modal.title',
    CookieSettingsModalDescription = 'cookie.settings.modal.description',
    SaveCurrentSelection = 'save.current.selection',
    CommonRejectAll = 'common.reject.all',
    CookieSettingsModalNecessaryTitle = 'cookie.settings.modal.necessary.title',
    CookieSettingsModalAnalyticsTitle = 'cookie.settings.modal.analytics.title',
    CookieSettingsModalAdvertisingTitle = 'cookie.settings.modal.advertising.title',
    CookieSettingsModalNecessaryDescription = 'cookie.settings.modal.necessary.description',
    CookieSettingsModalAnalyticsDescription = 'cookie.settings.modal.analytics.description',
    CookieSettingsModalAdvertisingDescription = 'cookie.settings.modal.advertising.description',
    UserAlreadyExists = 'user.already.exists',
    CommonYourInformation = 'common.your.information',
    CommonTownCity = 'common.town.city',
    BuildingHouseNameOrNumber = 'building.house.name.or.number',
    FooterBottomParagraph1 = 'footer.bottom.paragraph.1',
    FooterBottomParagraph2 = 'footer.bottom.paragraph.2',
    FooterBottomParagraph3 = 'footer.bottom.paragraph.3',
    FooterBottomParagraph4 = 'footer.bottom.paragraph.4',
    WhoCanOpenASendsAccount = 'who.can.open.a.sends.account',
    WhoCanOpenASendsAccountDescription = 'who.can.open.a.sends.account.description',
    WhichJurisdictionsAreAllowedToOpenAnAccount = 'which.jurisdictions.are.allowed.to.open.an.account',
    WhichJurisdictionsAreAllowedToOpenAnAccountDescription = 'which.jurisdictions.are.allowed.to.open.an.account.description',
    WhatDocumentsAreRequiredForOpeningAnAccount = 'what.documents.are.required.for.opening.an.account',
    WhatDocumentsAreRequiredForOpeningAnAccountDescription = 'what.documents.are.required.for.opening.an.account.description',
    WhichCurrenciesAreAvailable = 'which.currencies.are.available',
    WhichCurrenciesAreAvailableDescription = 'which.currencies.are.available.description',
    WillIHaveAPersonalIban = 'will.i.have.a.personal.iban',
    WillIHaveAPersonalIbanDescription = 'will.i.have.a.personal.iban.description',
    WhatAreTheTermsForOpeningAnAccount = 'what.are.the.terms.for.opening.an.account',
    WhatAreTheTermsForOpeningAnAccountDescription = 'what.are.the.terms.for.opening.an.account.description',
    VisitOurSupportCenterForMoreInformation = 'visit.our.support.center.for.more.information',
    CommonSupportCenter = 'common.support.center',
    LogisticsTransportationAndWarehousing = 'logistics.transportation.and.warehousing',
    ArtsEntertainmentAndRecreation = 'arts.entertainment.and.recreation',
    EducationalServices = 'educational.services',
    WholesaleTrade = 'wholesale.trade',
    RetailTrade = 'retail.trade',
    RealEstateAndRentalAndLeasing = 'real.estate.and.rental.and.leasing',
    AccommodationAndFoodServiceActivities = 'accommodation.and.food.service.activities',
    HumanHealthAndSocialWorkActivities = 'human.health.and.social.work.activities',
    InformationAndCommunication = 'information.and.communication',
    FinancialAndInsuranceActivities = 'financial.and.insurance.activities',
    ProfessionalScientificAndTechnicalActivities = 'professional.scientific.and.technical.activities',
    OnlineRetail = 'online.retail',
    AdvisoryFinancialServices = 'advisory.financial.services',
    CommonTechnology = 'common.technology',
    CountryOfOperationActivities = 'country.of.operation.activities',
    FooterNavLegalSmartflowTitle = 'footer.nav.legal.smartflow.title',
    SmartflowPrivateLimitedCompany = 'smartflow.private.limited.company',
    SmartflowTitle = 'smartflow.title',
    SmartflowDescription1 = 'smartflow.description_1',
    SmartflowSubTitle1 = 'smartflow.sub.title_1',
    SmartflowByAdress = 'smartflow.by.adress',
    SmartflowAdress = 'smartflow.adress',
    SmartflowCompanyType = 'smartflow.company.type',
    SmartflowCompanyTypeDesc = 'smartflow.company.type.desc',
    SmartflowIncorporated = 'smartflow.incorporated',
    SmartflowIncorporatedTitle = 'smartflow.incorporated.title',
    SmartflowNatureTitle = 'smartflow.nature.title',
    SmartflowListDescription1 = 'smartflow.list.description_1',
    SmartflowListDescription2 = 'smartflow.list.description_2',
    MetricsMainPageTitle = 'metrics.main.page.title',
    MainPageHeading = 'main.page.heading',
    MetricsMainPageDescription = 'metrics.main.page.description',
    MetricsOnlineAccountPageTitle = 'metrics.online.account.page.title',
    MetricsOnlineAccountPageDescription = 'metrics.online.account.page.description',
    MetricsAcquiringPageTitle = 'metrics.acquiring.page.title',
    MetricsAcquiringPageDescription = 'metrics.acquiring.page.description',
    MetricsPayoutsPageTitle = 'metrics.payouts.page.title',
    MetricsPayoutsPageDescription = 'metrics.payouts.page.description',
    PayoutsPageHeading = 'payouts.page.heading',
    MetricsBusinessPageTitle = 'metrics.business.page.title',
    MetricsBusinessPageDescription = 'metrics.business.page.description',
    BusinessPageHeading = 'business.page.heading',
    MetricsPricingPageTitle = 'metrics.pricing.page.title',
    MetricsPricingPageDescription = 'metrics.pricing.page.description',
    MetricsTermsAndConditionsPageTitle = 'metrics.terms.and.conditions.page.title',
    TermsAndConditionsPageHeading = 'terms.and.conditions.page.heading',
    MetricsPrivacyPolicyPageTitle = 'metrics.privacy.policy.page.title',
    MetricsPrivacyPolicyPageDescription = 'metrics.privacy.policy.page.description',
    MetricsComplaintsPageTitle = 'metrics.complaints.page.title',
    MetricsComplaintsPageDescription = 'metrics.complaints.page.description',
    CommonLegalDocuments = 'common.legal.documents',
    HrDataPrivacyNotice = 'hr.data.privacy.notice',
    AcquiringServicesAgreement = 'acquiring.services.agreement',
    CookiePolicy = 'cookie.policy',
    ComplaintPolicy = 'complaint.policy',
    ModernSlaveryStatement = 'modern.slavery.statement',
    ValueMissionStatementVt = 'value.mission.statement.vt',
    CommonHome = 'common.home',
    ValueMissionStatementHeading = 'value.mission.statement.heading',
    ValueMissionStatementDescription = 'value.mission.statement.description',
    ValueMissionStatementTitle = 'value.mission.statement.title',
    ModernSlaveryStatementHeading = 'modern.slavery.statement.heading',
    ModernSlaveryStatementTitle = 'modern.slavery.statement.title',
    ModernSlaveryStatementDescription = 'modern.slavery.statement.description',
    AcquiringServicesAgreementHeading = 'acquiring.services.agreement.heading',
    AcquiringServicesAgreementDescription = 'acquiring.services.agreement.description',
    AcquiringServicesAgreementTitle = 'acquiring.services.agreement.title',
    LegalDocumentsHeading = 'legal.documents.heading',
    LegalDocumentsDescription = 'legal.documents.description',
    LegalDocumentsTitle = 'legal.documents.title',
    CookiePolicyHeading = 'cookie.policy.heading',
    CookiePolicyTitle = 'cookie.policy.title',
    CookiePolicyDescription = 'cookie.policy.description',
    HumanResourcesPrivacyHeading = 'human.resources.privacy.heading',
    HumanResourcesPrivacyDescription = 'human.resources.privacy.description',
    HumanResourcesPrivacyTitle = 'human.resources.privacy.title',
    AnnualGrossProfit = 'annual.gross.profit',
    CommonSpanish = 'common.spanish',
    CommonGerman = 'common.german',
    CommonFrench = 'common.french',
    EnterYourMail = 'enter.your.mail',
    ThisSiteIsProtectedByRecaptchaAndTheGoogle = 'this.site.is.protected.by.recaptcha.and.the.google',
    CommonAnd = 'common.and',
    TermsOfService = 'terms.of.service',
    CommonApply = 'common.apply',
    EnterFirstName = 'enter.first.name',
    EnterLastName = 'enter.last.name',
    CommonTransfer = 'common.transfer',
    TransferDescription = 'transfer.description',
    CommonAcquire = 'common.acquire',
    AcquireDescription = 'acquire.description',
    PayDescription = 'pay.description',
    ManageDescription = 'manage.description',
    CommonECommerce = 'common.e.commerce',
    CommonMerchants = 'common.merchants',
    Customer1ExampleName = 'customer1.example.name',
    Customer1ExampleLocation = 'customer1.example.location',
    OpenedSendsAccountWithin48Hours = 'opened.sends.account.within.48.hours',
    ManagesTheAccountInTheAppAndWebVersions = 'manages.the.account.in.the.app.and.web.versions',
    PaysWithoutHiddenFees = 'pays.without.hidden.fees',
    MakesEuropeanAndInternationalTransfers = 'makes.european.and.international.transfers',
    Customer2ExampleName = 'customer2.example.name',
    CommonEntrepreneur = 'common.entrepreneur',
    Customer2ExampleLocation = 'customer2.example.location',
    HasNoLimitsOnDepositingAndWithdrawingMoney = 'has.no.limits.on.depositing.and.withdrawing.money',
    GeneratesBankStatementsInOneClickInsideTheAccount = 'generates.bank.statements.in.one.click.inside.the.account',
    ConnectedInternetAcquiringWithLowCommission = 'connected.Internet.acquiring.with.low.commission',
    SetUpSeparateAccountManagementPermissionsForEmployees = 'set.up.separate.account.management.permissions.for.employees',
    Customer3ExampleName = 'customer3.example.name',
    Customer3ExampleRole = 'customer3.example.role',
    OpenedAnAccountWithoutAnOfficeAndPhysicalAddress = 'opened.an.account.without.an.office.and.physical.address',
    PayAndGetPaidOnTheSameDayEvenOnWeekendsAndHolidays = 'pay.and.get.paid.on.the.same.day.even.on.weekends.and.holidays',
    AnalyzeDashboardsWithValuableData = 'analyze.dashboards.with.valuable.data',
    CreateAnUnlimitedNumberOfAccounts = 'create.an.unlimited.number.of.accounts',
    Customer4ExampleName = 'customer4.example.name',
    Customer4ExampleRole = 'customer4.example.role',
    OpenedAGbrAndEurAccountWithin48Hours = 'opened.a.gbr.and.eur.account.within.48.hours',
    MakeFastCardPayoutsToFreelancers = 'make.fast.card.payouts.to.freelancers',
    UseTheAccountToReceiveInvestments = 'use.the.account.to.receive.investments',
    MakeEuropeanAndInternationalTransfers = 'make.european.and.international.transfers',
    CommonGoBack = 'common.go.back',
    ContactUsPageTitle = 'contact.us.page.title',
    ContactUsPageDescription = 'contact.us.page.description',
    CommonAddress = 'common.address',
    CommonSocialMedia = 'common.social.media',
    ForDetailedInformationAddress = 'for.detailed.information.address',
    ForAllMarketingAndPrActivitiesPleaseWriteToUsAt = 'for.all.marketing.and.pr.activities.please.write.to.us.at',
    CommonAboutOurCompany = 'common.about.our.company',
    CommonWhatWeDo = 'common.what.we.do',
    WhatWeDoDescription = 'what.we.do.description',
    CommonSendsProvides = 'common.sends.provides',
    CommonPersonalAndBusinessAccounts = 'common.personal.and.business.accounts',
    OpenYourSepaUkLocalPaymentsAccountWithin48Hours = 'open.your.sepa.uk.local.payments.account.within.48.hours',
    CommonPaymentAcceptance = 'common.payment.acceptance',
    GetYourFirstTransactionTodayByConnectingSendsAcquiringWithApplePayGooglePay = 'get.your.first.transaction.today.by.connecting.sends.acquiring.with.apple.pay.google.pay',
    AreYouWorkingWithFreelancersMakeFastPayoutsToCardsOrIbansFromYourSendsAccount = 'are.you.working.with.freelancers.make.fast.payouts.to.cards.or.ibans.from.your.sends.account',
    AboutUsCommonDescription1 = 'about.us.common.description1',
    AboutUsCommonDescription2 = 'about.us.common.description2',
    SendsIsAMemberOf = 'sends.is.a.member.of',
    WeHaveBeenFeaturedIn = 'we.have.been.featured.in',
    MeetTheDirectorMlroInSends = 'meet.the.director.mlro.in.sends',
    CommonAnastasiiaPervushyna = 'common.anastasiia.pervushyna',
    CommonDirectorMlroInSends = 'common.director.mlro.in.sends',
    DirectorMlroInSendsDescription = 'director.mlro.in.sends.description',
    YourPersonalAndBusinessFintechEcosystem = 'your.personal.and.business.fintech.ecosystem',
    AboutUsTitle = 'about.us.title',
    AboutUsDescription = 'about.us.description',
    CommonOurCompany = 'common.our.company',
    CareersPageTitle = 'careers.page.title',
    CareersPageHeading = 'careers.page.heading',
    CareersPageDescription = 'careers.page.description',
    Vacancy1PageTitle = 'vacancy1.page.title',
    Vacancy1PageDescription = 'vacancy1.page.description',
    Vacancy2PageTitle = 'vacancy2.page.title',
    Vacancy2PageDescription = 'vacancy2.page.description',
    Vacancy3PageTitle = 'vacancy3.page.title',
    Vacancy3PageDescription = 'vacancy3.page.description',
    Vacancy4PageTitle = 'vacancy4.page.title',
    Vacancy4PageDescription = 'vacancy4.page.description',
    Vacancy1Description = 'vacancy1.description',
    CommonFullTime = 'common.full.time',
    CommonViewDetails = 'common.view.details',
    Vacancy2Description = 'vacancy2.description',
    Vacancy3Description = 'vacancy3.description',
    Vacancy4Description = 'vacancy4.description',
    Vacancy5PageTitle = 'vacancy5.page.title',
    Vacancy5PageDescription = 'vacancy5.page.description',
    Vacancy5Description = 'vacancy5.description',
    CommonLondonRemote = 'common.london.remote',
    CommonApplyNow = 'common.apply.now',
    CommonYouWill = 'common.you.will',
    ManageContentAcrossAllPlatformsIncludingWebsitesAndSocialMedia = 'manage.content.across.all.platforms.including.websites.and.social.media',
    WriteEditAndProofreadContentForSocialsWebNewslettersEtc = 'write.edit.and.proofread.content.for.socials.web.newsletters.etc',
    ManageAContentTeamConsistingOfFreelanceWritersGraphicDesignersVideographersEtc = 'manage.a.content.team.consisting.of.freelance.writers.graphic.designers.videographers.etc',
    FormulateACrossPlatformContentStrategy = 'formulate.a.cross.platform.content.strategy',
    BrainstormWithPrManagerToDevelopNewIdeas = 'brainstorm.with.pr.manager.to.develop.new.ideas',
    BuildAFollowingOnSocialMediaAndModerateAllRequestsFromTheCommunity = 'build.a.following.on.social.media.and.moderate.all.requests.from.the.community',
    TrackAnalyticsSocialsWebToAscertainContentEngagementLevelsPrepareDetailedSocialMediaActivityReports = 'track.analytics.socials.web.to.ascertain.content.engagement.levels.prepare.detailed.social.media.activity.reports',
    RunSponsoredCampaignsOnSocialMediaWithADigitalManagerToSupportCompanyGoals = 'run.sponsored.campaigns.on.social.media.with.a.digital.manager.to.support.company.goals',
    CommonItsAllAboutYou = 'common.its.all.about.you',
    TwoYearsOfPreviousExperienceInSimilarRolesAndAPortfolioOfRelevantWorkAndProjects = 'two.years.of.previous.experience.in.similar.roles.and.a.portfolio.of.relevant.work.and.projects',
    CopywritingBackgroundEnglish = 'copywriting.background.english',
    AnalyticalAndCreativeSkillsFollowedByASenseOfVisualAesthetics = 'analytical.and.creative.skills.followed.by.a.sense.of.visual.aesthetics',
    TeamManagementExperienceOrExperienceWorkingWithAGroupOfFreelancersWithWhomYouManagedToGetTasksDone = 'team.management.experience.or.experience.working.with.a.group.of.freelancers.with.whom.you.managed.to.get.tasks.done',
    CuriosityAboutTheCreationOfCommunityAndThePowerOfFintechAndEagernessToLearnAboutNewIndustries = 'curiosity.about.the.creation.of.community.and.the.power.of.fintech.and.eagerness.to.learn.about.new.industries',
    LoveSocialMediaAndHaveADeepUnderstandingOfAllTheCurrentTrends = 'love.social.media.and.have.a.deep.understanding.of.all.the.current.trends',
    ATeamPlayer = 'a.team.player',
    CommonYouWillHave = 'common.you.will.have',
    TheCoolestTeamEver = 'the.coolest.team.ever',
    YouWillJoinStrivingAndOpenMindedProfessionalsToShareIdeasAndShapeTheFuture = 'you.will.join.striving.and.open.minded.professionals.to.share.ideas.and.shape.the.future',
    TimeOffPolicyToTakeYourTime = 'time.off.policy.to.take.your.time',
    PaidVacationLongAndShortTermSickLeavesCompensatoryDaysOffMaternityAndPaternityLeavesAndMore = 'paid.vacation.long.and.short.term.sick.leaves.compensatory.days.off.maternity.and.paternity.leaves.and.more',
    CommonMedicalInsurance = 'common.medical.insurance',
    PerfectlyPackedHealthCareCoveringDentalAndCovid19Cases = 'perfectly.packed.health.care.covering.dental.and.covid.19.cases',
    BoostingYourExpertise = 'boosting.your.expertise',
    WeAreInterestedInAcceleratingYourGrowthHenceWeCompensateForYourProfessionalDevelopmentCoursesAndConferences = 'we.are.interested.in.accelerating.your.growth.hence.we.compensate.for.your.professional.development.courses.and.conferences',
    HowIsTheHiringProcessGoing = 'how.is.the.hiring.process.going',
    OurHiringProcessIsDesignedToEnsureThatWeAreAPerfectMatchSoBeReadyToComeAcross = 'our.hiring.process.is.designed.to.ensure.that.we.are.a.perfect.match.so.be.ready.to.come.across',
    InterviewWithRecruiterWeWillTalkAboutSendsAndAnswerAllYourQuestions = 'interview.with.recruiter.we.will.talk.about.sends.and.answer.all.your.questions',
    TestTaskWeWantToSeeHowYouThinkBringIdeasAndWorkWithInfoThenWeWillGiveYouFeedback = 'test.task.we.want.to.see.how.you.think.bring.ideas.and.work.with.info.then.we.will.give.you.feedback',
    IntroCallWithYourFutureManagerWeWillDiscussTheRoleAndTheProjectInDetailAndMakeSureOurCultureAndValuesClick = 'intro.call.with.your.future.manager.we.will.discuss.the.role.and.the.project.in.detail.and.make.sure.our.culture.and.values.click',
    ReferenceCheckDuringTheProcessWeWillContactYourPreviousWorkplacesToHearSomethingEvenMoreExcitingAboutYou = 'reference.check.during.the.process.we.will.contact.your.previous.workplaces.to.hear.something.even.more.exciting.about.you',
    WeWillReviewYourCvAndEverythingYouHaveSentUsWithinFiveWorkingDaysIfYourBackgroundMeetsOurNeeds = 'we.will.review.your.cv.and.everything.you.have.sent.us.within.five.working.days.if.your.background.meets.our.needs',
    ShareTheVacancy = 'share.the.vacancy',
    JoinOurTeam = 'join.our.team',
    CommonName = 'common.name',
    CommonLinkedin = 'common.linkedin',
    LinkedinProfileLink = 'linkedin.profile.link',
    OverseeTheCompanysBudgetingForecastingAndFinancialAnalysis = 'oversee.the.companys.budgeting.forecasting.and.financial.analysis',
    ReviewDevelopAndImproveInternalFinancialPoliciesAndProceduresWithASpecificFocusOnManagerialAccountancyPolicy = 'review.develop.and.improve.internal.financial.policies.and.procedures.with.a.specific.focus.on.managerial.accountancy.policy',
    EnsureComplianceWithFinancialRegulationsAndStandards = 'ensure.compliance.with.financial.regulations.and.standards',
    PrepareAndPresentFinancialReportsToSeniorManagement = 'prepare.and.present.financial.reports.to.senior.management',
    CollaborateWithOtherTeamsToOptimizeFinancialOperationsAndImproveFinancialPerformance = 'collaborate.with.other.teams.to.optimize.financial.operations.and.improve.financial.performance',
    MentorAndDevelopFinanceTeamMembersManagingWorkAllocationTrainingProblemResolutionPerformanceEvaluation = 'mentor.and.develop.finance.team.members.managing.work.allocation.training.problem.resolution.performance.evaluation',
    TheBuildingOfAnEffectiveTeamDynamic = 'the.building.of.an.effective.team.dynamic',
    BachelorsDegreeInFinanceAccountingOrRelatedFieldCfaCpaOrRelevantCertificationWouldBeAPlus = 'bachelors.degree.in.finance.accounting.or.related.field.cfa.cpa.or.relevant.certification.would.be.a.plus',
    MinimumOf5YearsOfExperienceInFinanceIncludingManagementPositions = 'minimum.of.5.years.of.experience.in.finance.including.management.positions',
    ExtensiveKnowledgeOfFinancialRegulationsAndAccountingProcessesIncludingManagerialAccountingPractices = 'extensive.knowledge.of.financial.regulations.and.accounting.processes.including.managerial.accounting.practices',
    StrongAnalyticalDecisionMakingAndProblemSolvingSkills = 'strong.analytical.decision.making.and.problem.solving.skills',
    ExcellentLeadershipCommunicationAndInterpersonalSkillsProficiencyInFinanceSoftwareAndTechnologies = 'excellent.leadership.communication.and.interpersonal.skills.proficiency.in.finance.software.and.technologies',
    CreateOriginalHighQualityAndPersuasiveCopyForVariousPlatformsIncluding = 'create.original.high.quality.and.persuasive.copy.for.various.platforms.including',
    StayUpdatedWithTheLatestTrendsBreakthroughsAndDevelopmentsInTheFintech = 'stay.updated.with.the.latest.trends.breakthroughs.and.developments.in.the.fintech',
    DevelopCompellingNarrativesAndStorytellingTechniquesToCommunicateComplex = 'develop.compelling.narratives.and.storytelling.techniques.to.communicate.complex',
    WorkCloselyWithCrossFunctionalTeamsIncludingMarketingDesignProductAnd = 'work.closely.with.cross.functional.teams.including.marketing.design.product.and',
    OptimizeContentForSearchEnginesByIncorporatingRelevantKeywordsMeta = 'optimize.content.for.search.engines.by.incorporating.relevant.keywords.meta',
    BachelorsDegreeInEnglishCommunicationsJournalismOrARelatedFieldIsPreferred = 'bachelors.degree.in.english.communications.journalism.or.a.related.field.is.preferred',
    Minimum2YearsOfExperienceAsACopywriterContentWriterOrSimilarRole = 'minimum.2.years.of.experience.as.a.copywriter.content.writer.or.similar.role',
    ExceptionalCommandOfTheEnglishLanguageWithAKeenEyeForGrammarSpelling = 'exceptional.command.of.the.english.language.with.a.keen.eye.for.grammar.spelling',
    AbilityToQuicklyGraspComplexTechnicalConceptsAndEffectivelyCommunicate = 'ability.to.quickly.grasp.complex.technical.concepts.and.effectively.communicate',
    ATeamPlayerWithExcellentInterpersonalSkillsAbilityToCollaborateEffectively = 'a.team.player.with.excellent.interpersonal.skills.ability.to.collaborate.effectively',
    FamiliarityWithSeoPrinciplesAndBestPracticesToOptimizeContentForSearchEngines = 'familiarity.with.seo.principles.and.best.practices.to.optimize.content.for.search.engines',
    StrongOrganizationalSkillsWithTheAbilityToManageMultipleProjectsSimultaneouslyAndMeetTightDeadlines = 'strong.organizational.skills.with.the.ability.to.manage.multiple.projects.simultaneously.and.meet.tight.deadlines',
    ImplementNewFeaturesServicesAndApis = 'implement.new.features.services.and.apis',
    WriteReusableCodeAndLibraries = 'write.reusable.code.and.libraries',
    OptimizeTheApplicationForMaximumSpeedAndScalability = 'optimize.the.application.for.maximum.speed.and.scalability',
    ImplementSecurityAndDataProtection = 'implement.security.and.data.protection',
    WriteTestsAndMetricsMonitoringModules = 'write.tests.and.metrics.monitoring.modules',
    ReadyToWorkWithOnCallDuties = 'ready.to.work.with.on.call.duties',
    TwoYearsOfHandsOnExperienceDevelopingciCdMicroServicesArchitectureContainers = 'two.years.of.hands.on.experience.developingci.cd.micro.services.architecture.containers',
    ExperienceWithPm2DockerCompose = 'experience.with.pm2.docker.compose',
    ExperienceWithAmazonSqsS3 = 'experience.with.amazon.sqs.s3',
    ExperienceWithTypescript = 'experience.with.typescript',
    ExperienceWithGraphql = 'experience.with.graphql',
    ExperienceWithSequelizeTypeorm = 'experience.with.sequelize.typeorm',
    ExperienceWithDatabasesPostgresql = 'experience.with.databases.postgresql',
    PassionateAboutTechnologyEnjoysSolvingComplexProblemsAndLearningNewTechnologies = 'passionate.about.technology.enjoys.solving.complex.problems.and.learning.new.technologies',
    ATeamPlayerWithStrongCommunicationSkills = 'a.team.player.with.strong.communication.skills',
    UpperIntermediateAdvancedWrittenAndSpokenEnglish = 'upper.intermediate.advanced.written.and.spoken.english',
    DevelopFromScratchOrReuseExistingComponentsAndIntegrateThemIntoNewSolutions = 'develop.from.scratch.or.reuse.existing.components.and.integrate.them.into.new.solutions',
    DevelopCustomIntegrationSolutionsBetweenOurClientsSystemAndThirdPartySoftware = 'develop.custom.integration.solutions.between.our.clients.system.and.third.party.software',
    ParticipateInEstimationAndPlanningSessions = 'participate.in.estimation.and.planning.sessions',
    CodeDebugDocumentImplementAndMaintainSoftware = 'code.debug.document.implement.and.maintain.software',
    ConductCodeReviewsToEnsureAdherenceToBestPracticesCodingStandardsAndProjectSpecificQualityCriteria = 'conduct.code.reviews.to.ensure.adherence.to.best.practices.coding.standards.and.project.specific.quality.criteria',
    RespondToIdentifiedIssuesAndResolveThem = 'respond.to.identified.issues.and.resolve.them',
    CommercialPhpDevelopmentExperienceOf4Years = 'commercial.php.development.experience.of.4.years',
    ExperienceWorkingWithMysqlPostgresql = 'experience.working.with.mysql.postgresql',
    ExperienceWorkingWithSymfonyHighlyDesired = 'experience.working.with.symfony.highly.desired',
    ExperienceInWritingAsynchronousApplicationsModules = 'experience.in.writing.asynchronous.applications.modules',
    ExperienceIntegratingWithOtherProductsAndApis = 'experience.integrating.with.other.products.and.apis',
    ExperienceWorkingWithRestfulWebServicesSoapWebServicesPostgresqlMysql = 'experience.working.with.restful.web.services.soap.web.services.postgresql.mysql',
    StrongUnderstandingOfCloudBasedArchitectureDistributedArchitecture = 'strong.understanding.of.cloud.based.architecture.distributed.architecture',
    UnderstandingOfModernFrontEndDevelopmentFrameworks = 'understanding.of.modern.front.end.development.frameworks',
    ExperienceWithDoctrineHtmlCssJavascriptDesired = 'experience.with.doctrine.html.css.javascript.desired',
    ExperienceWorkingWithAgile = 'experience.working.with.agile',
    TheCountryOfCompanyRegistration = 'the.country.of.company.registration',
    CommonChooseCountry = 'common.choose.country',
    TheCountryOfCompanyActualAddress = 'the.country.of.company.actual.address',
    CommonCompanyInfo = 'common.company.info',
    SelectTheCountryOfCompanyRegistrationAndTheCountryOfCompanyActualAddress = 'select.the.country.of.company.registration.and.the.country.of.company.actual.address',
    SelectTheCountryOfYourCitizenshipAndTheCountryOfYourResidency = 'select.the.country.of.your.citizenship.and.the.country.of.your.residency',
    TheCountryOfResidency = 'the.country.of.residency',
    TheCountryOfCitizenship = 'the.country.of.citizenship',
    CommissionAccountMonthlySubscription = 'commission_account_monthly_subscription',
    SorryOurServiceIsntAvailableInYourCountryYet = 'sorry.our.service.isnt.available.in.your.country.yet',
    AndYouWillBeTheFirstToKnowWhenTheServiceBecomesAvailableInTheCountry = 'and.you.will.be.the.first.to.know.when.the.service.becomes.available.in.the.country',
    CompleteVeriticationTextPrivate = 'complete.veritication.text.private',
    CompleteVeriticationTextLegal = 'complete.veritication.text.legal',
    CommonView = 'common.view',
    CommonVerification = 'common.verification',
    TheVerificationProcessWasCompletedSuccessfully = 'the.verification.process.was.completed.successfully',
    UnfortunatelyTheVerificationIsUnsuccessful = 'unfortunately.the.verification.is.unsuccessful',
    ContactSendsTeam = 'contact.sends.team',
    WhatIsAnIban = 'what.is.an.iban',
    AnIbanOrInternationalBankAccountNumberIsAStandardizedFormatFor = 'an.iban.or.international.bank.account.number.is.a.standardized.format.for',
    HowToGetTheIban = 'how.to.get.the.iban',
    HowLongDoesIbanTransferTake = 'how.long.does.iban.transfer.take',
    SendsUsesSepaInstantForIbanTransfersSoTypicallyItTakesUpTo10Minutes = 'sends.uses.sepa.instant.for.iban.transfers.so.typically.it.takes.up.to.10.minutes',
    SendsCanProvideYouWithABankAccountInEurAndGbrUsdIsComingSoon = 'sends.can.provide.you.with.a.bank.account.in.eur.and.gbr.usd.is.coming.soon',
    WhatIsAcquiringInPayments = 'what.is.acquiring.in.payments',
    InTheContextOfPaymentsAcquiringRefersToTheProcessByWhichAMerchant = 'in.the.context.of.payments.acquiring.refers.to.the.process.by.which.a.merchant',
    WhatTypesOfPaymentAcceptanceAreAvailableInSends = 'what.types.of.payment.acceptance.are.available.in.sends',
    SendsProvidesCardAcceptanceAndAlternativeECommercePaymentMethods = 'sends.provides.card.acceptance.and.alternative.e.commerce.payment.methods',
    HowToAcceptPaymentsOnline = 'how.to.accept.payments.online',
    ToAcceptOnlinePaymentsYouNeedToChooseAReliableFinancialServiceProvider = 'to.accept.online.payments.you.need.to.choose.a.reliable.financial.service.provider',
    WhatRateAppliesInSends = 'what.rate.applies.in.sends',
    ClientRatesDependOnTheTypeOfBusinessAndTransactionVolumes = 'client.rates.depend.on.the.type.of.business.and.transaction.volumes',
    HowToTransferMoneyInternationally = 'how.to.transfer.money.internationally',
    AfterOpeningASendsAccountYouCanEasilyMakeInternationalTransfersDirectly = 'after.opening.a.sends.account.you.can.easily.make.international.transfers.directly',
    WhatIsTheFeeForInternationalMoneyTransfersInSends = 'what.is.the.fee.for.international.money.transfers.in.sends',
    TheFeeMayVaryFrom2To4ForCardPayouts = 'the.fee.may.vary.from.2.to4.for.card.payouts',
    HowLongDoesAnInternationalMoneyTransferTake = 'how.long.does.an.international.money.transfer.take',
    WithSendsCardPayoutsYouCanSendMoneyInLessThanAMinute = 'with.sends.card.payouts.you.can.send.money.in.less.than.a.minute',
    WhatCurrenciesAreAvailableForInternationalMoneyTransfersInSends = 'what.currencies.are.available.for.international.money.transfers.in.sends',
    CardPayoutsCanBePossibleInEurInsideAndOutsideTheEu = 'card.payouts.can.be.possible.in.eur.inside.and.outside.the.eu',
    HowToOpenABusinessBankAccountOnline = 'how.to.open.a.business.bank.account.online',
    EveryStepOfApplyingForABusinessAccountIsCompletedOnline = 'every.step.of.applying.for.a.business.account.is.completed.online',
    CurrentlyEurAndGbpCurrenciesAreAvailableInSends = 'currently.eur.and.gbp.currencies.are.available.in.sends',
    WhoCanOpenASendsBusinessAccount = 'who.can.open.a.sends.business.account',
    SendsOpensBusinessAccountsOnlyForEuAndUkBasedCompanies = 'sends.opens.business.accounts.only.for.eu.and.uk.based.companies',
    ToOpenABusinessAccountYouNeedACompanyRegistryExtractAndDetailed = 'to.open.a.business.account.you.need.a.company.registry.extract.and.detailed',
    EveryClientWhoHasSuccessfullyPassedKycAndOnboardingWillReceiveEurAndGbpAccounts = 'every.client.who.has.successfully.passed.kyc.and.onboarding.will.receive.eur.and.gbp.accounts',
    AllYouNeedInOneApp = 'all.you.need.in.one.app',
    TransferReceiveAndControlYourMoney = 'transfer.receive.and.control.your.money',
    SendsFinancialAppIsCreatedForIosAndAndroidUsersThroughoutTheEuropean = 'sends.financial.app.is.created.for.ios.and.android.users.throughout.the.european',
    WithSendsMobileAppYouCan = 'with.sends.mobile.app.you.can',
    PayAndGetPaidOnTheSameDay = 'pay.and.get.paid.on.the.same.day',
    ControlPaymentsWithRealTimeNotifications = 'control.payments.with.real.time.notifications',
    StayInTouchWithSendsSupport247 = 'stay.in.touch.with.sends.support.24.7',
    SecureTransfersWithTwoFactorAuthentication = 'secure.transfers.with.two.factor.authentication',
    TransferMoneyWithoutBorders = 'transfer.money.without.borders',
    FillOutTheFormAndGetAnActiveAccountWithin48Hours = 'fill.out.the.form.and.get.an.active.account.within.48.hours',
    SaveWithUsTwoTimesMoreThanWithTheLeadingBanksOfGreatBritain = 'save.with.us.two.times.more.than.with.the.leading.banks.of.great.britain',
    Over1000ProfessionalsWorkWithSends = 'over.1000.professionals.work.with.sends',
    GoGlobalWithSendsBusinessAccount = 'go.global.with.sends.business.account',
    PayEmployeesGetPaidAndManageCompanyCashFlowInDifferentCurrencies = 'pay.employees.get.paid.and.manage.company.cash.flow.in.different.currencies',
    OpenABusinessAccount = 'open.a.business.account',
    GetMoreInformation = 'get.more.information',
    MobileAppPageTitle = 'mobile.app.page.title',
    MobileAppPageDescription = 'mobile.app.page.description',
    CommonOpenAnAccount = 'common.open.an.account',
    CommonGetFreeAccount = 'common.get.free.account',
    CommonCheckStatus = 'common.check.status',
    SmartflowPageTitle = 'smartflow.page.title',
    SmartflowPageDescription = 'smartflow.page.description',
    IfYouWantToUpdateYourProfileInformationPleaseContactOurSupportTeam = 'if.you.want.to.update.your.profile.information.please.contact.our.support.team',
    FollowOurLatestNews = 'follow.our.latest.news',
    CommonEmailAddress = 'common.email.address',
    CommonSubscribe = 'common.subscribe',
    CommonAboutUs = 'common.about.us',
    CommonOurTeam = 'common.our.team',
    CommonNewsAndBlog = 'common.news.and.blog',
    CommonApplication = 'common.application',
    AddingForeignCurrencies = 'adding.foreign.currencies',
    CommonPaymentIn = 'common.payment.in',
    FromSendsAccount = 'from.sends.account',
    CommonSepaTransfer = 'common.sepa.transfer',
    CommonFasterPayments = 'common.faster.payments',
    CommonPaymentOut = 'common.payment.out',
    CommonToSendsAccount = 'common.to.sends.account',
    CommonPersonalAccount = 'common.personal.account',
    CommonPersonal = 'common.personal',
    TheApplicationIsSuccessfullyCreatedAndVerified = 'the.application.is.successfully.created.and.verified',
    SendsPlatformIsUndergoingEssentialUpgradesForABetterUserExperience = 'sends.platform.is.undergoing.essential.upgrades.for.a.better.user.experience',
    CommonContact = 'common.contact',
    MakeANewTransfer = 'make.a.new.transfer',
    WithinSepaZone = 'within.sepa.zone',
    CommonInternationalPayment = 'common.international.payment',
    CommonUkLocalPayment = 'common.uk.local.payment',
    LocalGbpPayments = 'local.gbp.payments',
    InternalMoneyTransfers = 'internal.money.transfers',
    CommonSwift = 'common.swift',
    CommonSepa = 'common.sepa',
    CommonOtherServices = 'common.other.services',
    CommonNew = 'common.new',
    YourAccountForTheInternetAcquiringServiceIsNowFullySetUp = 'your.account.for.the.internet.acquiring.service.is.now.fully.set.up',
    TheInvitationLinkHasBeenSentToYourEmail = 'the.invitation.link.has.been.sent.to.your.email',
    IfYouRequireAssistanceDuringTheOnboardingProcess = 'if.you.require.assistance.during.the.onboarding.process',
    ToBeginUtilizingTheInternetAcquiringService = 'to.begin.utilizing.the.internet.acquiring.service',
    WillBeAvailableSoon = 'will.be.available.soon',
    AcceptOnlineCardPaymentsDirectToYourCard = 'accept.online.card.payments.direct.to.your.card',
    PaymentFeeToCardPayment = 'payment.fee.to.card.payment',
    CommonActivate = 'common.activate',
    OpenYourMulticurrencyAccountAndTransferMoneyEverywhereYouWantWith = 'open.your.multicurrency.account.and.transfer.money.everywhere.you.want.with',
    CommonGetIban = 'common.get.iban',
    TwoFactorAuthentication = 'two.factor.authentication',
    CommonSms = 'common.sms',
    CommonAuthenticatorApp = 'common.authenticator.app',
    CommonDeactivate = 'common.deactivate',
    CommonActivated = 'common.activated',
    DownloadGoogleAuthenticatorApplicationForIosOrAndroid = 'download.google.authenticator.application.for.ios.or.android',
    EnterTheCodeForYourAccountFromTheApplication = 'enter.the.code.for.your.account.from.the.application.',
    EnterTheCode = 'enter.the.code',
    IfYoudLikeToChangeYourPasswordPleaseEnterYourCurrentPassword = 'if.youd.like.to.change.your.password.please.enter.your.current.password',
    ChangesHaveNotBeenChangedPleaseTryAgainLater = 'changes.have.not.been.changed.please.try.again.later',
    CommonSave = 'common.save',
    CommonDefault = 'common.default',
    CommonSends = 'common.sends',
    EnterTheCodeToYourAccountGeneratedByGoogleAuthenticatorApplication = 'enter.the.code.to.your.account.generated.by.google.authenticator.application',
    CommonOtherMethods = 'common.other.methods',
    MakeFastSecureAndCostEffectiveBankTransfersInEur = 'make.fast.secure.and.cost..effective.bank.transfers.in.eur',
    TransferFundsGloballyInEurUsdAndGbpWithSwiftPayments = 'transfer.funds.globally.in.eur.usd.and.gbp.with.swift.payments',
    MakeFastSecureAndCostEffectiveBankTransfersToUkBankAccountsInGbp = 'make.fast.secure.and.cost.effective.bank.transfers.to.uk.bank.accounts.in.gbp',
    ChooseThePaymentType = 'choose.the.payment.type',
    CommonRecipientInfo = 'common.recipient.info',
    RecipientBankName = 'recipient.bank.name',
    RecipientBankCountry = 'recipient.bank.country',
    PaymentWithinSepaZone = 'payment.within.sepa.zone',
    OptionallyAttachFile = 'optionally.attach.file',
    CommonInvoiceNumber = 'common.invoice.number',
    EnterInvoiceNumber = 'enter.invoice.number',
    CommonInvoiceDate = 'common.invoice.date',
    CommonCharitynumber = 'common.charitynumber',
    EnterCharityNumber = 'enter.charity.number',
    WrongIbanTheCountryOfTheRecipientsBankMustBeGreatBritain = 'wrong.iban.the.country.of.the.recipients.bank.must.be.great.britain',
    TheCountryOfTheRecipientsBankIsNotInSepaZone = 'the.country.of.the.recipients.bank.is.not.in.sepa.zone',
    TheCountryOfTheRecipientsBankIsNotPermitted = 'the.country.of.the.recipients.bank.is.not.permitted',
    TheCountryOfTheRecipientsBankIsNotInSepaZoneWithoutLink = 'the.country.of.the.recipients.bank.is.not.in.sepa.zone.without.link',
    IbanAccountNumber = 'iban.account.number',
    IfTheSwiftIncomingPaymentIsInCurrencyOtherThan = 'if.the.swift.incoming.payment.is.in.currency.other.than',
    CommonSessions = 'common.sessions',
    CommonActiveSessions = 'common.active.sessions',
    CommonOtherSessions = 'common.other.sessions',
    TerminateAllOtherSessions = 'terminate.all.other.sessions',
    SessionsTermination = 'sessions.termination',
    DoYouWantToTerminateAllOtherSessionsExceptTheActiveOne = 'do.you.want.to.terminate.all.other.sessions.except.the.active.one',
    AllSessionsSuccessfullyTerminated = 'all.sessions.successfully.terminated',
    CommonActiveNow = 'common.active.now',
    ThroughSendsApplication = 'through.sends.application',
    CommonGoogleAuthenticator = 'common.google.authenticator',
    WeHaveSentAPushNotificationToYourSendsApplication = 'we.have.sent.a.push.notification.to.your.sends.application',
    CommonCancelTransaction = 'common.cancel.transaction',
    AreYouSureYouWantToCancelTransaction = 'are.you.sure.you.want.to.cancel.transaction',
    YourAccountWillBeAvailableHere = 'your.account.will.be.available.here',
    ItUsuallyTakes24HoursToCompleteOurStandardChecksAndAssessments = 'it.usually.takes.24.hours.to.complete.our.standard.checks.and.assessments',
    AccountForStatement = 'account.for.statement',
    OtherMethodsAreAvailableVia = 'other.methods.are.available.via',
    CommonProfileInformation = 'common.profile.information',
    ForAllOtherIssues = 'for.all.other.issues',
    CommonLogOut = 'common.log.out',
    CommonEditEmail = 'common.edit.email',
    YourEmailHasBeenSuccessfullyChanged = 'your.email.has.been.successfully.changed',
    PermittedJurisdictionForIncomingOrOutgoingPaymentsAreLimited = 'permitted.jurisdiction.for.incoming.or.outgoing.payments.are.limited',
    AnyPaymentCreatedWithAPayerFromABlacklistedJurisdictionIsFailed = 'any.payment.created.with.a.payer.from.a.blacklisted.jurisdiction.is.failed',
    PleaseSeeBelowTheListOfBlacklistedCountries = 'please.see.below.the.list.of.blacklisted.countries',
    SorrySomethingWentWrongPleaseTryAgainLater = 'sorry.something.went.wrong.please.try.again.later',
    CommonOnHold = 'common.on.hold',
    AfterTheBalanceTopUpYouWillBeCharged = 'after.the.balance.top.up.you.will.be.charged',
    TomorrowYouWillBeChargedMonthlySubscriptionFee = 'tomorrow.you.will.be.charged.monthly.subscription.fee',
    RecoveryYourPassword = 'recovery.your.password',
    CommonLocalFps = 'common.local.fps',
    PleaseUseTheFollowingAccountDetailsToFundYourAccountInGbpEurAndUsd = 'please.use.the.following.account.details.to.fund.your.account.in.gbp.eur.and.usd',
    IfYouCannotSpecifyTheIntermediaryBankThenPleaseIncludeTheIntermediary = 'if.you.cannot.specify.the.intermediary.bank.then.please.include.the.intermediary',
    IntermediarySwiftBic = 'intermediary.swift.bic',
    PleaseUseTheFollowingAccountDetailsToFundYourAccountInEurFrom = 'please.use.the.following.account.details.to.fund.your.account.in.eur.from',
    PleaseUseTheFollowingAccountDetailsToFundYourAccountInGbrFrom = 'please.use.the.following.account.details.to.fund.your.account.in.gbr.from',
    CommonAccountName = 'common.account.name',
    CommonSwiftBic = 'common.swift.bic',
    CommonBank = 'common.bank',
    CommonAvailableSoon = 'common.available.soon',
    DateOfCreation = 'date.of.creation',
    LastPaymentStatusUpdate = 'last.payment.status.update',
    CommonMin = 'common.min',
    ThisPriceScheduleIsValidFrom = 'this.price.schedule.is.valid.from',
    YourAccountIsCurrentlyBlockedOurSupportTeamIsReadyToAssistYouWith = 'your.account.is.currently.blocked.our.support.team.is.ready.to.assist.you.with',
    YouDoNotHavePermissionPleaseContactOurSupportTeam = 'you.do.not.have.permission.please.contact.our.support.team',
    CommonExchange = 'common.exchange',
    ExchangeMoneyAtCompetitiveCurrencyRatesWith = 'exchange.money.at.competitive.currency.rates.with',
    CurrencyExchangeRatesForYourBusinessWithoutSpreads = 'currency.exchange.rates.for.your.business.without.spreads',
    CommonYouReceive = 'common.you.receive',
    NumberOfExchangeRateRequestsExceeded = 'number.of.exchange.rate.requests.exceeded',
    PleaseNoteThatExchangeRatesAreDynamic = 'please.note.that.exchange.rates.are.dynamic',
    CommonRate = 'common.rate',
    PasswordHasBeenSuccessfullyChanged = 'password.has.been.successfully.changed',
    AnEmailWithPasswordReset = 'an.email.with.password.reset',
    InstructionsHaveBeenSentToYourEmailAddress = 'instructions.have.been.sent.to.your.email.address',
    CommonSignTransaction = 'common.sign.transaction',
    IncomingPaymentsPricingUpdateForIndividualsAndLegalEntities = 'incoming.payments.pricing.update.for.individuals.and.legal.entities',
    UpdateOfTheDateFromNewIncomingTransfersPricing = 'update.of.the.dateFrom.new.incoming.transfers.pricing ',
    OpenNewPricing = 'open.new.pricing',
    ServiceAvailableForTheUkCompaniesOnly = 'service.available.for.the.uk.companies.only.',
    IfYouDoNotHoldTheRequiredAmountInGbpTheAccountOpeningFeeWillBeCharged = 'if.you.do.not.hold.the.required.amount.in.gbp.the.account.opening.fee.will.be.charged',
    FollowingCurrencyExchangeRates = 'following.currency.exchange.rates',
    CommonComingSoon = 'common.coming.soon',
    CommonStatement = 'common.statement',
    FreeOfChargeWireTransferFromYourAccountToAnotherSendsUserAccount = 'free.of.charge.wire.transfer.from.your.account.to.another.sends.user.account',
    CommonToSendsUser = 'common.to.sends.user',
    EmailOrSendsIban = 'email.or.sends.iban',
    ContactUsHeading = 'contact.us.heading',
    WeAreSorryButPageYouAreLookingForCanNotBeFound = 'we.are.sorry.but.page.you.are.looking.for.can.not.be.found',
    CommonReturnHome = 'common.return.home',
    CommonYouSold = 'common.you.sold',
    CommonYouGot = 'common.you.got',
    YouPaidInTotal = 'you.paid.in.total',
    CommonRecipientAccount = 'common.recipient.account',
    IndicateInReferenceToPayment = 'indicate.in.reference.to.payment',
    ScamsReimbursement = 'scams.reimbursement',
    ScamsReimbursementTitle = 'scams.reimbursement.title',
    ScamsReimbursementDescription = 'scams.reimbursement.description',
}
