import NavigationSeparator from '@shared-asset/vector/chevron-left.svg?react';
import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';
import {
    StepsNavigationItem,
    StepsNavigationWrapper,
} from '@page/main-pages/common/steps-navigation/steps-navigation.styles';

type PrivacyNavigationType = {
    secondLevel: {
        path: PageEnum;
        title: LocalizationEnum;
    };
    thirdLevel?:
    | {
        path: PageEnum;
        title: LocalizationEnum;
    }
    | undefined;
};

export const StepsNavigation = ({ secondLevel, thirdLevel = undefined }: PrivacyNavigationType) => (
    <StepsNavigationWrapper>
        <Link route={PageEnum.Home}>
            <StepsNavigationItem>
                <Translate langKey={LocalizationEnum.CommonHome} />
            </StepsNavigationItem>
        </Link>
        <NavigationSeparator />
        <Link route={secondLevel.path}>
            <StepsNavigationItem active={Boolean(thirdLevel) === false}>
                <Translate langKey={secondLevel.title} />
            </StepsNavigationItem>
        </Link>
        {!!thirdLevel && (
            <>
                <NavigationSeparator />
                <Link route={thirdLevel.path}>
                    <StepsNavigationItem active>
                        <Translate langKey={thirdLevel.title} />
                    </StepsNavigationItem>
                </Link>
            </>
        )}
    </StepsNavigationWrapper>
);
