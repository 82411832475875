import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

interface StepsNavigationItemProps {
    active?: boolean;
}

export const StepsNavigationWrapper = styled.div`
    ${Flex.Row.VerticalCenter.Style};
    padding: 20px 0;
    gap: 4px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 10px 0;
    }
    svg {
        min-width: 8px;
    }
`;
export const StepsNavigationItem = styled.div<StepsNavigationItemProps>`
    ${({ active = false }) =>
        active
            ? `${Typography.Paragraph12.Medium.Neutral500.Center.Style}; pointer-events: none;`
            : `${Typography.Paragraph12.Medium.Neutral900.Center.Style}; cursor: pointer;`};
`;
