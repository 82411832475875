import { lazy } from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import { User, UserType } from '@shared-graphql';

import { PageEnum } from '@enum/page.enum';
import AuthPartnersSuccessPage from '@page/auth-pages/auth-partners/auth-partners-success/auth-partners-success';
import AuthPartnersPage from '@page/auth-pages/auth-partners/auth-partners.page';
import ForgotPasswordPage from '@page/auth-pages/forgot-password/forgot-password';
import LoginPage from '@page/auth-pages/login/login.page';
import ResetPasswordPage from '@page/auth-pages/reset-password/reset-password';
import VerificationPage from '@page/auth-pages/verification/verification';
import MainPagesPage from '@page/main-pages/main-pages.page';
import PageRoot from '@page/page-root';
import RegistrationAdditionalFields from '@page/registration/registration-additional-fields/registration-additional-fields';
import RegistrationOnboarding from '@page/registration/registration-onboarding/registration-onboarding';
import RegistrationOtp from '@page/registration/registration-otp/registration-otp';
import RegistrationPassword from '@page/registration/registration-password/registration-password';
import RegistrationPhone from '@page/registration/registration-phone/registration-phone';
import WithProviders from '@page/with-providers';
import WorkspacePage from '@page/workspace/workspace.page';
import { rootStore } from '@store/root-store';
import { isCompanyExist } from '@util/company/is-company-exist';
import { isUserExist } from '@util/user/is-user-exist';
import { isUserPermissionsExist } from '@util/user/is-user-permissions-exist';
import ScamsReimbursement from '@page/main-pages/scams-reimbursement/scams-reimbursement';

const NotFound = lazy(() => import('@page/not-found/not-found'));
const RegistrationPage = lazy(() => import('@page/registration/registration.page'));
const MobileAppPagesPage = lazy(() => import('@page/mobile-app-pages/mobile-app-pages.page'));
const HomePage = lazy(() => import('@page/main-pages/home/home'));
const BusinessHomePage = lazy(() => import('@page/main-pages/business/business-home'));
const AcquiringPage = lazy(() => import('@page/main-pages/acquiring/acquiring'));
const PayoutsPage = lazy(() => import('@page/main-pages/payouts/payouts'));
const OnlineAccountPage = lazy(() => import('@page/main-pages/online-account/online-account-page'));
const PricesPage = lazy(() => import('@page/main-pages/pricing/prices'));
const ContactUsPage = lazy(() => import('@page/main-pages/contact-us/contact-us'));
const AboutUsPage = lazy(() => import('@page/main-pages/about-us/about-us'));
const CareersPage = lazy(() => import('@page/main-pages/careers/careers'));
const MobileAppPage = lazy(() => import('@page/main-pages/mobile-app/mobile-app'));
const VacancyContentAndSMMManagerPage = lazy(
    () => import('@page/main-pages/vacancy/content-and-smm-manager/content-and-smm-manager')
);
const VacancySeniorFinanceManagerPage = lazy(
    () => import('@page/main-pages/vacancy/senior-finance-manager/senior-finance-manager')
);
const VacancyCopywriterPage = lazy(() => import('@page/main-pages/vacancy/copywriter/copywriter'));
const VacancyNodeJsDeveloperPage = lazy(() => import('@page/main-pages/vacancy/node-js-developer/node-js-developer'));
const VacancySeniorPHPDeveloperPage = lazy(
    () => import('@page/main-pages/vacancy/senior-php-developer/senior-php-developer')
);
const TermsAndConditionsPage = lazy(() => import('@page/main-pages/terms-and-conditions/terms-and-conditions'));
const ComplaintsPage = lazy(() => import('@page/main-pages/complaints/complaints'));
const Smartflow = lazy(() => import('@page/main-pages/smartflow/smartflow'));
const PrivacyPolicyPage = lazy(() => import('@page/main-pages/privacy-policy/privacy-policy'));
const HRDataPrivacyNoticePage = lazy(() => import('@page/main-pages/hr-data-privacy-notice/hr-data-privacy-notice'));
const AcquiringServicesAgreementPage = lazy(
    () => import('@page/main-pages/acquiring-services-agreement/acquiring-services-agreement')
);
const CookiePolicyPage = lazy(() => import('@page/main-pages/cookie-policy/cookie-policy'));
const ModernSlaveryStatementPage = lazy(
    () => import('@page/main-pages/modern-slavery-statement/modern-slavery-statement')
);
const ValueMissionStatementPage = lazy(
    () => import('@page/main-pages/value-mission-statement/value-mission-statement')
);
const LegalDocumentsPage = lazy(() => import('@page/main-pages/legal-documents/legal-documents'));
const MobileAppTermsAndConditionsPage = lazy(
    () => import('@page/main-pages/terms-and-conditions/mobile-app-terms-and-conditions')
);
const DashboardPage = lazy(() => import('@page/workspace/dashboard/dashboard.page'));
const CommissionProfilePage = lazy(() => import('@page/workspace/commission-profile/commission-profile.page'));
const CabinetAcquiringPage = lazy(() => import('@page/workspace/cabinet-acquiring/cabinet-acquiring.page'));
const PermittedCountriesPage = lazy(() => import('@page/workspace/permitted-countries/permitted-countries.page'));
const NotificationsPage = lazy(() => import('@page/workspace/notifications/notifications.page'));
const SettingsPage = lazy(() => import('@page/workspace/settings/settings.page'));

export const routes = createBrowserRouter([
    {
        element: <WithProviders />,
        errorElement: <NotFound />,
        children: [
            {
                path: PageEnum.Home,
                element: <PageRoot />,
                children: [
                    {
                        element: <MainPagesPage />,
                        loader: async () => {
                            if (!isUserExist((rootStore.user.user ?? {}) as unknown as User)) {
                                await rootStore.user.clearUserData();
                                await rootStore.user.loadCurrentUser();
                            }
                            return null;
                        },
                        children: [
                            { index: true, element: <HomePage /> },
                            { path: PageEnum.BusinessHome, element: <BusinessHomePage /> },
                            { path: PageEnum.Acquiring, element: <AcquiringPage /> },
                            { path: PageEnum.Payouts, element: <PayoutsPage /> },
                            { path: PageEnum.Prices, element: <PricesPage /> },
                            { path: PageEnum.ContactUs, element: <ContactUsPage /> },
                            { path: PageEnum.AboutUs, element: <AboutUsPage /> },
                            { path: PageEnum.Careers, element: <CareersPage /> },
                            { path: PageEnum.MobileApp, element: <MobileAppPage /> },
                            {
                                path: PageEnum.VacancyContentAndSMMManager,
                                element: <VacancyContentAndSMMManagerPage />,
                            },
                            {
                                path: PageEnum.VacancySeniorFinanceManager,
                                element: <VacancySeniorFinanceManagerPage />,
                            },
                            { path: PageEnum.VacancyCopywriter, element: <VacancyCopywriterPage /> },
                            { path: PageEnum.VacancyNodeJsDeveloper, element: <VacancyNodeJsDeveloperPage /> },
                            { path: PageEnum.VacancySeniorPHPDeveloper, element: <VacancySeniorPHPDeveloperPage /> },
                            { path: PageEnum.TermsAndConditions, element: <TermsAndConditionsPage /> },
                            { path: PageEnum.Complaints, element: <ComplaintsPage /> },
                            { path: PageEnum.Smartflow, element: <Smartflow /> },
                            { path: PageEnum.PrivacyPolicy, element: <PrivacyPolicyPage /> },
                            { path: PageEnum.OnlineAccount, element: <OnlineAccountPage /> },
                            { path: PageEnum.HRDataPrivacyNotice, element: <HRDataPrivacyNoticePage /> },
                            { path: PageEnum.AcquiringServicesAgreement, element: <AcquiringServicesAgreementPage /> },
                            { path: PageEnum.CookiePolicy, element: <CookiePolicyPage /> },
                            { path: PageEnum.ModernSlaveryStatement, element: <ModernSlaveryStatementPage /> },
                            { path: PageEnum.ValueMissionStatement, element: <ValueMissionStatementPage /> },
                            { path: PageEnum.ScamsReimbursement, element: <ScamsReimbursement /> },
                            { path: PageEnum.LegalDocuments, element: <LegalDocumentsPage /> },
                        ],
                    },
                    {
                        element: <RegistrationPage />,
                        children: [
                            { path: PageEnum.OnBoarding, element: <RegistrationOnboarding /> },
                            { path: PageEnum.RegistrationAdditionalFields, element: <RegistrationAdditionalFields /> },
                            { path: PageEnum.RegistrationPassword, element: <RegistrationPassword /> },
                            { path: PageEnum.RegistrationPhone, element: <RegistrationPhone /> },
                            { path: PageEnum.RegistrationOtp, element: <RegistrationOtp /> },
                        ],
                    },
                    {
                        element: <MobileAppPagesPage />,
                        children: [
                            { path: PageEnum.MobileAppComplaints, element: <ComplaintsPage withNavigation={false} /> },
                            {
                                path: PageEnum.MobileAppTermsAndConditions,
                                element: <MobileAppTermsAndConditionsPage withNavigation={false} />,
                            },
                            {
                                path: PageEnum.MobileAppPrivacyPolicy,
                                element: <PrivacyPolicyPage withNavigation={false} />,
                            },
                            {
                                path: PageEnum.MobileAppHRDataPrivacyNotice,
                                element: <HRDataPrivacyNoticePage withNavigation={false} />,
                            },
                            {
                                path: PageEnum.MobileAppAcquiringServicesAgreement,
                                element: <AcquiringServicesAgreementPage withNavigation={false} />,
                            },
                            {
                                path: PageEnum.MobileAppModernSlaveryStatement,
                                element: <ModernSlaveryStatementPage withNavigation={false} />,
                            },
                            {
                                path: PageEnum.MobileAppValueMissionStatement,
                                element: <ValueMissionStatementPage withNavigation={false} />,
                            },
                            {
                                path: PageEnum.MobileScamsReimbursement,
                                element: <ScamsReimbursement withNavigation={false} />,
                            },
                        ],
                    },
                    {
                        path: PageEnum.Login,
                        element: <LoginPage />,
                        loader: async () => {
                            if (!isUserExist((rootStore.user.user ?? {}) as unknown as User)) {
                                await rootStore.user.clearUserData();
                                await rootStore.company.clearCompanyData();
                                await rootStore.featureTags.clearFeatureTags();
                                await rootStore.transactions.clearTransactions();
                            }
                            return null;
                        },
                    },
                    {
                        path: PageEnum.Verification,
                        element: <VerificationPage />,
                        loader: async () => {
                            if (!isUserExist((rootStore.user.user ?? {}) as unknown as User)) {
                                await rootStore.user.loadCurrentUser();
                                await rootStore.featureTags.loadFeatureTags();
                            }
                            if (!isUserExist((rootStore.user.user ?? {}) as unknown as User)) {
                                return redirect(PageEnum.Login);
                            }
                            return null;
                        },
                    },
                    { path: PageEnum.ForgotPassword, element: <ForgotPasswordPage /> },
                    { path: PageEnum.ResetPassword, element: <ResetPasswordPage /> },
                    { path: PageEnum.AuthPartners, element: <AuthPartnersPage /> },
                    { path: PageEnum.AuthPartnersSuccess, element: <AuthPartnersSuccessPage /> },
                ],
            },
            {
                element: <WorkspacePage />,
                loader: async () => {
                    if (!isUserExist((rootStore.user.user ?? {}) as unknown as User)) {
                        await rootStore.user.loadCurrentUser();
                        await rootStore.featureTags.loadFeatureTags();
                    }
                    if (!isUserExist((rootStore.user.user ?? {}) as unknown as User)) {
                        return redirect(PageEnum.Login);
                    }

                    if (!isUserPermissionsExist((rootStore.user.user ?? {}) as unknown as User)) {
                        await rootStore.user.loadCurrentUser();
                        await rootStore.featureTags.loadFeatureTags();
                    }

                    if (rootStore.user.user.type === UserType.Private) {
                        if (new Set([PageEnum.CabinetAcquiring]).has(document.location.pathname as PageEnum)) {
                            return redirect(PageEnum.Dashboard);
                        }
                    }

                    if (rootStore.user.user.type === UserType.Legal) {
                        if (!isCompanyExist(rootStore.company.company)) {
                            await rootStore.company.company.loadCompany();
                        }
                    }

                    return null;
                },
                children: [
                    { path: PageEnum.Dashboard, element: <DashboardPage /> },
                    { path: PageEnum.CommissionProfile, element: <CommissionProfilePage /> },
                    { path: PageEnum.CabinetAcquiring, element: <CabinetAcquiringPage /> },
                    { path: PageEnum.PermittedCountries, element: <PermittedCountriesPage /> },
                    { path: PageEnum.Notification, element: <NotificationsPage /> },
                    { path: PageEnum.Settings, element: <SettingsPage /> },
                ],
            },
        ],
    },
]);
